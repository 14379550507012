export default {
  "container": "AH",
  "summary": "AO",
  "sideview": "Am",
  "steps-container": "Ap",
  "invoice-upload-container": "Ax",
  "pdf-preview": "Aw",
  "uploader": "AN",
  "template-text": "Av",
  "file-dropzone": "Ao",
  "overlay": "An",
  "footer": "Ai",
  "upload-prompt": "AA",
  "step-selector": "AY",
  "disclaimer": "Ah"
};

export default {
  "notifications-settings": "cJh",
  "opened": "cJf",
  "icon": "cJK",
  "header": "cJG",
  "header-toggle": "cJr",
  "avatar-image": "cJb",
  "title": "cJy",
  "icon--right": "cJU",
  "toggle-container": "cJj",
  "toggle": "cJW"
};

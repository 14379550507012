export default {
  "option": "caI",
  "account-description": "cat",
  "account-identification": "caM",
  "account-avatar": "caP",
  "account-name": "cal body-2",
  "account-balance": "caX body-2",
  "external-accounts-disclaimer": "caC",
  "status": "cak body-2",
  "failed-connection": "caJ"
};

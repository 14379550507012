export default {
  "wrapper": "ctB",
  "content": "ctE",
  "back": "ctd btn btn--tertiary",
  "heading": "ctI",
  "heading-content": "ctt",
  "logo": "ctM",
  "description": "ctP body-2",
  "cta": "ctl"
};

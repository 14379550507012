/* import __COLOCATED_TEMPLATE__ from './share.hbs'; */
import Component from '@glimmer/component';

import { receivableInvoicesURL } from 'qonto/constants/hosts';
import { CopyToClipboard } from 'qonto/react/shared/components/copy-to-clipboard';

interface PaymentLinkSidebarShareSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PaymentLinkSidebarShare extends Component<PaymentLinkSidebarShareSignature> {
  CopyToClipboard = CopyToClipboard;

  get shareableLink() {
    // @ts-expect-error
    return `${receivableInvoicesURL}/invoices/${this.args.resourceId}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Sidebar::Share': typeof PaymentLinkSidebarShare;
  }
}

export default {
  "account-container": "cLb",
  "badge": "cLy",
  "subtitle": "cLU title-4",
  "accounts-filter": "cLj",
  "account-filter-label": "cLW",
  "status": "cLu",
  "account-info": "cTS",
  "balance": "cTc",
  "revealed-balance": "cTq",
  "hidden-balance": "cTZ"
};

export default {
  "container": "ctx",
  "header": "ctw",
  "content": "ctN",
  "info-list": "ctv",
  "info-label": "cto body-2",
  "info-value": "ctn body-2",
  "provider": "cti",
  "policy-container": "ctA",
  "policy-label": "ctY body-2",
  "policy-number": "cth body-2",
  "copy-to-clipboard": "ctf",
  "button": "ctK btn btn--primary btn--stretch btn--large"
};

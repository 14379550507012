export default {
  "selector": "Kl",
  "metal-design": "KX",
  "content": "KC",
  "header": "Kk",
  "big": "KJ",
  "header-name": "Ks",
  "subtitle": "KF",
  "button": "Kg",
  "features": "KD",
  "feature-link": "KV body-link"
};

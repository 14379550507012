/* import __COLOCATED_TEMPLATE__ from './intro.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ChecksImage } from 'qonto/react/components/checks/image';

interface FlowsCheckIntroSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCheckIntroComponent extends Component<FlowsCheckIntroSignature> {
  disclaimerBlock = Disclaimer.Block;
  checksImage = ChecksImage;

  @service declare segment: Services['segment'];
  @service declare store: Services['store'];

  handleNext = dropTask(async () => {
    this.segment.track('checks_deposit_started');

    try {
      await this.store
        // @ts-expect-error
        .adapterFor('check')
        // @ts-expect-error
        .getMandate(this.args.context.check.get('organization.id'));

      // @ts-expect-error
      this.args.context.hasValidMandate = true;
    } catch (error) {
      // @ts-expect-error
      if (error.status === 404) {
        // @ts-expect-error
        this.args.context.hasValidMandate = false;
      }
    }

    // @ts-expect-error
    this.args.transitionToNext();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Check::Intro': typeof FlowsCheckIntroComponent;
  }
}

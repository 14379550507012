export default {
  "summary-description": "ceZ",
  "detail": "ceR",
  "detail-value": "ceQ",
  "account-icon": "cee",
  "color-secondary": "ceB",
  "beneficiary-info": "ceE",
  "currency": "ced",
  "beneficiary-name": "ceI",
  "separator": "cet",
  "instant-subtitle": "ceM",
  "instant-wrapper": "ceP",
  "instant-header": "cel",
  "instant-toggle": "ceX",
  "instant--disabled": "ceC",
  "limit-exceeded-disclaimer": "cek",
  "sticky-panel": "ceJ",
  "total-interest": "ces",
  "tooltip-icon": "ceF",
  "tooltip": "ceg",
  "payment-options": "ceD",
  "standard-transfer-tooltip": "ceV"
};

/* import __COLOCATED_TEMPLATE__ from './attachment-actions.hbs'; */
import Component from '@glimmer/component';

import { Button } from '@repo/design-system-kit';

interface InvoiceSettingsFormTermsAndConditionsAttachmentActionsSignature {
  // The arguments accepted by the component
  Args: {
    settings: any;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSettingsFormTermsAndConditionsAttachmentActionsComponent extends Component<InvoiceSettingsFormTermsAndConditionsAttachmentActionsSignature> {
  button = Button;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::Form::TermsAndConditions::AttachmentActions': typeof InvoiceSettingsFormTermsAndConditionsAttachmentActionsComponent;
  }
}

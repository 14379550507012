export default {
  "container": "YY",
  "container-content": "Yh",
  "content": "Yf",
  "underline": "YK",
  "inline-content": "YG",
  "payment-rate": "Yr",
  "payment-subtitle": "Yb",
  "grey-text": "Yy",
  "earnings-header": "YU",
  "earnings-tooltip": "Yj"
};

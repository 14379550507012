export default {
  "container": "Aj",
  "customize-field": "AW",
  "content": "Au",
  "sideview": "YS",
  "form": "Yc",
  "footer": "Yq",
  "pdf-preview": "YZ",
  "de-layout": "YR",
  "de-pdf-preview": "YQ",
  "german-preview": "Ye"
};

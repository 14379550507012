export default {
  "container": "cJZ",
  "close-button": "cJR",
  "content-wrapper": "cJQ",
  "main": "cJe",
  "description": "cJB",
  "calculation-item": "cJE",
  "total-km": "cJd",
  "calculation-details": "cJI",
  "btn": "cJt"
};

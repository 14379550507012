export default {
  "container": "is",
  "company-details": "iF",
  "sideview": "ig",
  "form": "iD",
  "french-company-details-fieldset": "iV",
  "footer": "iL",
  "pdf-preview": "iT",
  "de-layout": "iz",
  "de-pdf-preview": "ia",
  "german-preview": "iH"
};

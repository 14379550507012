export default {
  "container": "Kb",
  "centered": "Ky",
  "content": "KU",
  "spinner": "Kj",
  "holder-selector-wrapper": "KW mb-16",
  "holder-selector-error": "Ku",
  "info-text": "GS",
  "error-text": "Gc",
  "lottie-illustration": "Gq",
  "info-text-1": "GZ",
  "info-text-2": "GR"
};

/* import __COLOCATED_TEMPLATE__ from './create.hbs'; */
import { setProperties } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { property } from 'es-toolkit/compat';

import type { FlowStepArgs } from 'qonto/components/flow-in-flow';
import {
  ERROR_CODE,
  ERROR_TRANSLATION_KEY,
  INVALID_ERROR_POINTER_PREFIX,
} from 'qonto/constants/international-out/beneficiary';
import { CURRENCIES } from 'qonto/constants/international-out/currency';
import { EVENTS } from 'qonto/constants/international-out/tracking';
import { State } from 'qonto/react/components/transfers/international-out/state';
import type { DataContext } from 'qonto/routes/flows/setup/transfers/international-out/data-context';
import type { Requirements } from 'qonto/services/international-out/types';
import type { Validator } from 'qonto/utils/dynamic-form';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { extractFieldKeyFromPointer } from 'qonto/utils/international-out/error';
import { applyDefaultValues } from 'qonto/utils/international-out/requirements';

interface Signature {
  Args: FlowStepArgs<
    DataContext & {
      fees: NonNullable<DataContext['fees']>;
      quote: NonNullable<DataContext['quote']>;
    }
  >;
}

export default class FlowsTransfersInternationalOutBeneficiaryCreateComponent extends Component<Signature> {
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  state = State;

  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  @tracked requirements: Requirements[] = [];
  @tracked validators: Record<string, Validator> = {};
  @tracked isError = false;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    this.loadInitialRequirementsTask.perform().catch(ignoreCancelation);
  }

  get notice(): { level: 'warning'; content: string } | null {
    switch (this.args.context.quote.targetCurrency) {
      case CURRENCIES.JPY:
        return {
          level: 'warning',
          content: this.intl.t('international-out.disclaimer.jpy.text', {
            // @ts-expect-error
            link: htmlSafe(
              `<a
                href="${this.intl.t('international-out.disclaimer.jpy.link', {
                  faqUrl: this.zendeskLocalization.getLocalizedArticle(4987654),
                })}"
                target="_blank"
                rel="noopener noreferrer"
                class="body-link"
                data-test-faq-link
              >${this.intl.t('international-out.disclaimer.jpy.linkText')}</a>`
            ),
            htmlSafe: true,
          }),
        };
      default:
        return null;
    }
  }

  get paymentMethods(): string[] {
    return this.requirements.map(({ type }) => type);
  }

  createBeneficiaryTask = dropTask(
    async ({ type: paymentMethod, data: beneficiaryDetails }): Promise<void> => {
      this.segment.track(EVENTS.BENEFICIARY_REQUIREMENTS_SUBMITTED);

      this.validators = {};

      const { context, transitionToNext } = this.args;
      const { id, targetCurrency } = context.quote;

      try {
        const {
          beneficiary,
          fees,
          quote: patchedQuote,
          targetAccountId,
        } = await this.internationalOutManager.createBeneficiary({
          quoteId: id,
          currency: targetCurrency,
          type: paymentMethod,
          details: beneficiaryDetails,
        });

        this.segment.track(EVENTS.BENEFICIARY_REQUIREMENTS_ACCEPTED);

        setProperties(context, {
          beneficiary,
          fees,
          quote: patchedQuote,
          targetAccount: { id: targetAccountId },
        });

        transitionToNext();
      } catch (error) {
        // @ts-expect-error
        const { status, errors } = error;

        let isKnownErrorWithTranslation = false;

        if (status === 422) {
          // @ts-expect-error
          errors.forEach(({ code, source }) => {
            const isKnownError = Object.values(ERROR_CODE).includes(code);
            const translationKey = ERROR_TRANSLATION_KEY[code];

            if (isKnownError && translationKey) {
              isKnownErrorWithTranslation = true;

              const pointer = source?.pointer;

              if (pointer) {
                const fieldKey = extractFieldKeyFromPointer({
                  pointer,
                  prefix: INVALID_ERROR_POINTER_PREFIX,
                });
                const invalidValue = property(fieldKey)(beneficiaryDetails);
                this.validators = {
                  ...this.validators,
                  [fieldKey]: {
                    predicate: value => value !== invalidValue,
                    translationKey,
                  },
                };
              } else {
                this.toastFlashMessages.toastError(this.intl.t(translationKey));
              }
            }
          });
        }

        if (!isKnownErrorWithTranslation) {
          this.#handleError(error);
        }
      }
    }
  );

  loadInitialRequirementsTask = dropTask(async (): Promise<void> => {
    this.isError = false;

    try {
      const requirements = await this.internationalOutManager.getBeneficiaryRequirements({
        quoteId: this.args.context.quote.id,
        currency: this.args.context.quote.targetCurrency,
      });
      this.#setRequirements(requirements);
    } catch {
      this.isError = true;
    }
  });

  refreshRequirementsTask = dropTask(async ({ data: beneficiaryDetails }): Promise<void> => {
    try {
      const requirements = await this.internationalOutManager.getBeneficiaryRequirements({
        quoteId: this.args.context.quote.id,
        currency: this.args.context.quote.targetCurrency,
        details: beneficiaryDetails,
      });
      this.#setRequirements(requirements);
    } catch (error) {
      this.#handleError(error);
    }
  });

  // @ts-expect-error
  #handleError(error): void {
    const { httpStatus, shouldSendToSentry } = ErrorInfo.for(error);

    // We don't want to capture 400 errors as they correspond to unknown validation errors from the provider.
    // An example could be an account number that was black-listed by the provider.
    if (shouldSendToSentry && httpStatus !== 400) {
      this.sentry.captureException(error);
    }

    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }

  #setRequirements(requirements: Requirements[]): void {
    this.requirements = applyDefaultValues(requirements, {
      accountHolderName: this.args.context.invoice?.supplierName ?? null,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::InternationalOut::Beneficiary::Create': typeof FlowsTransfersInternationalOutBeneficiaryCreateComponent;
  }
}

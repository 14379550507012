export default {
  "header": "cTo",
  "top-section": "cTn",
  "close": "cTi btn btn--icon-only btn--tertiary",
  "container": "cTA",
  "picto": "cTY",
  "avatar": "cTh",
  "details": "cTf",
  "expiration-date": "cTK",
  "payment-method": "cTG"
};

export default {
  "animated": "fz",
  "container": "fa",
  "cards-selectors": "fH",
  "card-selector": "fO",
  "selector": "fm",
  "header": "fp",
  "footer": "fx",
  "skip-cta": "fw",
  "pricing-caption": "fN",
  "show": "fv",
  "selectorFadeIn": "fo",
  "fadeIn": "fn",
  "asset-one": "fi",
  "onePlusAssetFadeIn": "fA",
  "assetMoveUp": "fY",
  "asset-plus": "fh",
  "asset-x": "ff",
  "xAssetFadein": "fK",
  "feature": "fG",
  "highlighted": "fr"
};

export default {
  "body": "ckX",
  "members": "ckC",
  "members-results": "ckk",
  "members-list": "ckJ",
  "members-title": "cks caption-bold",
  "member": "ckF",
  "empty-illustration": "ckg",
  "empty-title": "ckD title-3",
  "empty-description": "ckV body-2",
  "invitable-members": "ckL",
  "details": "ckT",
  "pagination-footer": "ckz"
};

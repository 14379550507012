export default {
  "container": "Kz",
  "container-metal": "Ka",
  "content": "KH",
  "button": "KO",
  "embossed-selector": "Km",
  "carousel-container": "Kp",
  "carousel": "Kx",
  "asset": "Kw",
  "poster": "KN",
  "video-embossed": "Kv",
  "video": "Ko",
  "active": "Kn",
  "embossed": "Ki",
  "safari": "KA",
  "poster-open": "KY"
};

/* import __COLOCATED_TEMPLATE__ from './feedback.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { OnboardingFeedback } from 'qonto/react/payment-links/components/flows/onboarding';

interface PaymentLinksOnboardingFeedbackSignature {
  // The arguments accepted by the component
  Args: {
    transitionToNext: () => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class PaymentLinksOnboardingFeedbackComponent extends Component<PaymentLinksOnboardingFeedbackSignature> {
  OnboardingFeedback = OnboardingFeedback;

  @service declare mollie: Services['mollie'];
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Onboarding::Feedback': typeof PaymentLinksOnboardingFeedbackComponent;
  }
}

export default {
  "bullet": "cDH",
  "slice-0": "cDO",
  "slice-1": "cDm",
  "slice-2": "cDp",
  "slice-3": "cDx",
  "slice-4": "cDw",
  "slice-5": "cDN",
  "slice-6": "cDv",
  "slice-other": "cDo",
  "slice-uncategorized": "cDn",
  "label": "cDi",
  "ellipsis": "cDA",
  "rest": "cDY",
  "previous-date-range": "cDh caption"
};

export default {
  "header-cell": "cTW",
  "method-col": "cTu",
  "expiration-col": "czS",
  "status-col": "czc",
  "amount-col": "czq",
  "with-sidebar": "czZ",
  "header-content": "czR caption-bold",
  "empty": "czQ",
  "align-right": "cze"
};

export default {
  "content": "cRv",
  "error-message": "cRo",
  "wrapper": "cRn",
  "banner": "cRi",
  "banner-content": "cRA",
  "banner-text": "cRY",
  "banner-image": "cRh",
  "banner-title": "cRf",
  "banner-description": "cRK body-2",
  "link": "cRG",
  "plans-comparison": "cRr",
  "plan-box": "cRb",
  "arrow": "cRy",
  "arrow-icon": "cRU",
  "link-downgrade": "cRj",
  "stroke-text": "cRW"
};

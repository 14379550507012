export default {
  "mandate-new-no-mandate": "jF",
  "left-side": "jg",
  "left-side__wrapper": "jD",
  "left-side__wrapper-title": "jV",
  "left-side__wrapper-details": "jL",
  "left-side__wrapper-details-item": "jT",
  "right-side": "jz",
  "multi-accounts": "ja",
  "right-side__wrapper": "jH",
  "right-side__wrapper-name": "jO",
  "right-side__wrapper-address": "jm",
  "right-side__wrapper-iban-dashed": "jp",
  "right-side__wrapper-address-label": "jx",
  "right-side__wrapper-iban-label": "jw",
  "right-side__wrapper-bic-label": "jN",
  "right-side__wrapper-address-dashed": "jv",
  "right-side__wrapper-bic-dashed": "jo",
  "dropdown-icon": "jn",
  "dropdown": "ji",
  "multi-accounts-illustration": "jA"
};

export default {
  "container": "iY",
  "content": "ih",
  "form": "if",
  "ctas": "iK",
  "sideview": "iG",
  "pdf-preview": "ir",
  "de-layout": "ib",
  "de-pdf-preview": "iy",
  "german-preview": "iU"
};

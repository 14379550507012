export default {
  "container": "cZh",
  "has-background": "cZf",
  "wrapper": "cZK",
  "error-wrapper": "cZG",
  "bottom-btns": "cZr",
  "bottom-wrapper": "cZb",
  "tab-nav": "cZy",
  "tab-nav-small": "cZU",
  "btn-clear": "cZj",
  "error-illustration": "cZW",
  "error-title": "cZu title-1",
  "disclaimer-wrapper": "cRS",
  "disclaimer": "cRc",
  "error-description": "cRq body-2",
  "table-wrapper": "cRZ",
  "title": "cRR title-1",
  "lineup-toggle": "cRQ",
  "description": "cRe body-2",
  "loading-wrapper": "cRB",
  "card": "cRE",
  "plan": "cRd",
  "placeholder-header": "cRI",
  "disclaimer-info": "cRt",
  "spinner": "cRM"
};

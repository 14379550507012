/* import __COLOCATED_TEMPLATE__ from './donut.hbs'; */
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, SkeletonLoader } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask, restartableTask } from 'ember-concurrency';
import { task as trackedTask } from 'ember-resources/util/ember-concurrency';
import { reads } from 'macro-decorators';

import { validatePeriod } from 'qonto/components/overview/chart/period-selector/custom-period/date-picker';
// @ts-expect-error
import { PERIOD_KEYS } from 'qonto/constants/overview';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { utcToZonedDate } from 'qonto/utils/chart';
// @ts-expect-error
import { formatDonutCashflows, sumDonutCashflows } from 'qonto/utils/format-cashflows';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const trackingEvents = {
  activity_tag: 'activity',
  counterparty_name: 'counterparty',
  label_lists: 'custom_label',
  initiator_id: 'member',
  team_id: 'team',
  current_month: 'month_to_date',
  current_quarter: 'quarter_to_date',
  current_year: 'year_to_date',
  last_30_days: 'last_30_days',
  last_3_months: 'last_3_months',
  last_12_months: 'last_12_months',
};

interface OverviewWidgetsDonutSignature {
  // The arguments accepted by the component
  Args: {
    isError?: boolean;
    isAdvancedFiltersMode?: boolean;
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class OverviewWidgetsDonutComponent extends Component<OverviewWidgetsDonutSignature> {
  badgeHighlight = Badge;
  placeholderLine = SkeletonLoader.Line;

  @service declare abilities: Services['abilities'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];
  @service declare store: Services['store'];
  @service declare userManager: Services['userManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  // @ts-expect-error
  @reads('organizationManager.membership.id') membershipId;
  // @ts-expect-error
  @reads('userManager.currentUser.timezone') timezone;

  @tracked _advancedFilters;
  // @ts-expect-error
  @tracked _selectedSource;
  // @ts-expect-error
  @tracked _selectedPeriod;
  didComponentMount = false;

  constructor(owner: unknown, args: OverviewWidgetsDonutSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let storageKey = `${this.args.side}-donut-${this.membershipId}`;
    // @ts-expect-error
    this.sourceStorageKey = `${storageKey}-source`;
    // @ts-expect-error
    this.periodStorageKey = `${storageKey}-period`;
    // @ts-expect-error
    this.filtersStorageKey = `${storageKey}-filters`;
    // @ts-expect-error
    let persistedFilters = JSON.parse(safeLocalStorage.getItem(this.filtersStorageKey))?.filters;

    // @ts-expect-error
    this.persistedSource = JSON.parse(safeLocalStorage.getItem(this.sourceStorageKey)) || {};
    // @ts-expect-error
    this.persistedPeriod = JSON.parse(safeLocalStorage.getItem(this.periodStorageKey)) || {};
    this._advancedFilters = this.abilities.can('access advanced overview')
      ? persistedFilters
      : undefined;

    // Unless scheduled at least to the `actions` queue, this would raise an
    // `autotracking.mutation-after-consumption` deprecation, which is an error
    // in Ember 4 and above.
    schedule('actions', () => {
      if (!this.args.isError) {
        this.onUpdateTask
          .perform(this.selectedPeriod, this.advancedFilters)
          .catch(ignoreCancelation);
      }
    });
  }

  categories = {
    // @ts-expect-error
    atm: this.intl.t('activities.atm'),
    // @ts-expect-error
    fees: this.intl.t('activities.fees'),
    // @ts-expect-error
    finance: this.intl.t('activities.finance'),
    // @ts-expect-error
    food_and_grocery: this.intl.t('activities.food_and_grocery'),
    // @ts-expect-error
    gas_station: this.intl.t('activities.gas_station'),
    // @ts-expect-error
    hardware_and_equipment: this.intl.t('activities.hardware_and_equipment'),
    // @ts-expect-error
    hotel_and_lodging: this.intl.t('activities.hotel_and_lodging'),
    // @ts-expect-error
    insurance: this.intl.t('activities.insurance'),
    // @ts-expect-error
    it_and_electronics: this.intl.t('activities.it_and_electronics'),
    // @ts-expect-error
    legal_and_accounting: this.intl.t('activities.legal_and_accounting'),
    // @ts-expect-error
    logistics: this.intl.t('activities.logistics'),
    // @ts-expect-error
    manufacturing: this.intl.t('activities.manufacturing'),
    // @ts-expect-error
    marketing: this.intl.t('activities.marketing'),
    // @ts-expect-error
    office_rental: this.intl.t('activities.office_rental'),
    // @ts-expect-error
    office_supply: this.intl.t('activities.office_supply'),
    // @ts-expect-error
    online_service: this.intl.t('activities.online_service'),
    // @ts-expect-error
    other_expense: this.intl.t('activities.other_expense'),
    // @ts-expect-error
    other_income: this.intl.t('activities.other_income'),
    // @ts-expect-error
    other_service: this.intl.t('activities.other_service'),
    // @ts-expect-error
    refund: this.intl.t('activities.refund'),
    // @ts-expect-error
    restaurant_and_bar: this.intl.t('activities.restaurant_and_bar'),
    // @ts-expect-error
    salary: this.intl.t('activities.salary'),
    // @ts-expect-error
    sales: this.intl.t('activities.sales'),
    // @ts-expect-error
    subscription: this.intl.t('activities.subscription'),
    // @ts-expect-error
    tax: this.intl.t('activities.tax'),
    // @ts-expect-error
    transport: this.intl.t('activities.transport'),
    // @ts-expect-error
    treasury_and_interco: this.intl.t('activities.treasury_and_interco'),
    // @ts-expect-error
    utility: this.intl.t('activities.utility'),
    // @ts-expect-error
    voucher: this.intl.t('activities.voucher'),
  };

  periods = [
    {
      // @ts-expect-error
      label: this.intl.t('overview.month-to-date'),
      interval: 'current_month',
    },
    {
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.quarter-to-date'),
      interval: 'current_quarter',
    },
    {
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.year-to-date'),
      interval: 'current_year',
    },
    {
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.last-thirty-days'),
      interval: 'last_30_days',
    },
    {
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.last-three-months'),
      interval: 'last_3_months',
    },
    {
      // @ts-expect-error
      label: this.intl.t('overview.period-selection.last-twelve-months'),
      interval: 'last_12_months',
    },
  ];

  activityTag = {
    key: 'activity_tag',
    // @ts-expect-error
    label: this.intl.t('overview.group-by.category'),
  };

  counterpartyName = {
    key: 'counterparty_name',
    label:
      // @ts-expect-error
      this.args.side === 'credit'
        ? // @ts-expect-error
          this.intl.t('overview.donut-chart.group-by.source')
        : // @ts-expect-error
          this.intl.t('overview.donut-chart.group-by.beneficiary'),
  };

  get trackingWidgetType() {
    // @ts-expect-error
    return this.args.side === 'credit' ? 'inflow_donut_chart' : 'outflow_donut_chart';
  }

  get shouldResetFilters() {
    // @ts-expect-error
    let includesTeamFilter = this._advancedFilters?.expressions?.some(group =>
      // @ts-expect-error
      group.expressions.some(({ property }) => property === 'team_id')
    );
    return includesTeamFilter && this.abilities.cannot('filter team');
  }

  get advancedFilters() {
    // @ts-expect-error
    if (this.args.advancedFilters) {
      // @ts-expect-error
      return this.args.advancedFilters;
    } else if (this.shouldResetFilters) {
      return undefined;
    } else {
      return this._advancedFilters;
    }
  }

  get bankAccounts() {
    // @ts-expect-error
    return this.args.bankAccounts ? [this.args.bankAccounts] : undefined;
  }

  get customLabelsList() {
    if (this.abilities.can('group by custom labels overview')) {
      // @ts-expect-error
      return this.organizationManager.organization.labelLists.map(({ id, name }) => ({
        key: 'label_lists',
        id,
        label: name,
      }));
    } else if (this.abilities.can('update overview')) {
      return [
        {
          key: 'label_lists',
          label: this.intl.t('overview.donut-chart.group-by.custom-label'),
          discover: true,
        },
      ];
    } else {
      return [];
    }
  }

  get team() {
    if (
      this.abilities.can('have teams overview') &&
      this.abilities.can('access advanced overview')
    ) {
      return [
        {
          key: 'team_id',
          label: this.intl.t('overview.donut-chart.group-by.team'),
        },
      ];
    } else if (this.abilities.can('have teams overview') && this.abilities.can('update overview')) {
      return [
        {
          key: 'team_id',
          label: this.intl.t('overview.donut-chart.group-by.team'),
          discover: true,
        },
      ];
    } else {
      return [];
    }
  }

  get outflowSources() {
    let outflowSources = [
      ...(!this.abilities.can('assign category') ? [this.activityTag] : []),
      {
        key: 'initiator_id',
        label: this.intl.t('overview.donut-chart.group-by.team-member'),
      },
      ...this.team,
      this.counterpartyName,
      ...this.customLabelsList,
    ];

    if (this.subscriptionManager.shouldHideUpsell) {
      outflowSources = outflowSources.filter(
        ({ key }) => !['initiator_id', 'counterparty_name'].includes(key)
      );
    }

    return outflowSources;
  }

  get inflowSources() {
    let inflowSources = [
      ...(!this.abilities.can('assign category') ? [this.activityTag] : []),
      this.counterpartyName,
      ...this.customLabelsList,
    ];

    if (this.subscriptionManager.shouldHideUpsell) {
      inflowSources = inflowSources.filter(({ key }) => key !== 'counterparty_name');
    }

    return inflowSources;
  }

  get sources() {
    // @ts-expect-error
    return this.args.side === 'credit' ? this.inflowSources : this.outflowSources;
  }

  get hasSources() {
    return this.sources.length > 0;
  }

  get shouldResetSource() {
    let shouldResetTeam =
      // @ts-expect-error
      this.persistedSource.key === 'team_id' &&
      (this.abilities.cannot('access advanced overview') ||
        this.abilities.cannot('have teams overview'));

    let shouldResetCustomLabels =
      // @ts-expect-error
      this.persistedSource.key === 'label_lists' &&
      this.abilities.cannot('group by custom labels overview');

    return shouldResetTeam || shouldResetCustomLabels;
  }
  get selectedSource() {
    if (this._selectedSource) {
      return this._selectedSource;
      // @ts-expect-error
    } else if (this.persistedSource.key && !this.shouldResetSource) {
      // @ts-expect-error
      return this._lookupSource(this.persistedSource);
    } else {
      if (this.sources.length > 0) {
        return this.sources[0];
      }
      return {};
    }
  }

  set selectedSource(source) {
    this._selectedSource = source;

    let currentSource;
    if (source.key === 'label_lists') {
      let { key, id } = source;
      currentSource = { key, id };
    } else {
      currentSource = { key: source.key };
    }

    if (!this.args.isAdvancedFiltersMode) {
      // @ts-expect-error
      safeLocalStorage.setItem(this.sourceStorageKey, JSON.stringify(currentSource));
    }
  }

  get shouldResetPeriod() {
    // @ts-expect-error
    let hasCustomPeriod = this.persistedPeriod.key === PERIOD_KEYS.CUSTOM_PERIOD;
    let cannotAccessAdvancedOverview = this.abilities.cannot('access advanced overview');
    let hasInvalidCustomPeriod =
      hasCustomPeriod &&
      // @ts-expect-error
      !validatePeriod(this.persistedPeriod.startDate, this.persistedPeriod.endDate);

    return (hasCustomPeriod && cannotAccessAdvancedOverview) || hasInvalidCustomPeriod;
  }

  get selectedPeriod() {
    if (this._selectedPeriod) {
      return this._selectedPeriod;
      // @ts-expect-error
    } else if (this.persistedPeriod.key && !this.shouldResetPeriod) {
      // @ts-expect-error
      return this._lookupPeriod(this.persistedPeriod);
    } else {
      return this.periods[0];
    }
  }

  set selectedPeriod(period) {
    this._selectedPeriod = period;

    let currentPeriod;
    if (period.interval) {
      currentPeriod = { key: period.interval };
    } else {
      let { key, startDate, endDate } = period;
      currentPeriod = {
        key,
        startDate: startDate.getTime(),
        endDate: endDate.getTime(),
      };
    }

    if (!this.args.isAdvancedFiltersMode) {
      // @ts-expect-error
      safeLocalStorage.setItem(this.periodStorageKey, JSON.stringify(currentPeriod));
    }
  }

  // @ts-expect-error
  get bounds() {
    // @ts-expect-error
    if (this.args.cashflows) {
      // @ts-expect-error
      let currentBounds = this.args.cashflows[0].meta?.bounds;
      // @ts-expect-error
      let previousBounds = this.args.cashflows[1]?.meta?.bounds;

      return {
        current: {
          min: utcToZonedDate(currentBounds?.min, this.timezone),
          max: utcToZonedDate(currentBounds?.max, this.timezone),
        },
        previous: {
          min: utcToZonedDate(previousBounds?.min, this.timezone),
          max: utcToZonedDate(previousBounds?.max, this.timezone),
        },
      };
    }
  }

  get selectedPeriodCashflows() {
    // @ts-expect-error
    return this._getCashflowsData(this.args.cashflows, 0);
  }

  get totalSelectedPeriodCashflows() {
    return sumDonutCashflows(this.selectedPeriodCashflows);
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription?.findTrial('advanced_dashboard');
  }

  @action onTrialClick() {
    this.modals.open('discover/trial/confirm', {
      isFullScreenModal: true,
      trialInfo: this.trialInfo,
    });
  }

  getAugmentedCashflowsTask = dropTask(async cashflows => {
    let augmentedCashflows;

    if (cashflows) {
      if (this.selectedSource.key === 'activity_tag') {
        // @ts-expect-error
        augmentedCashflows = cashflows.map(item => ({
          ...item,
          // @ts-expect-error
          name: this.categories[item.name] || item.name,
        }));
      } else if (this.selectedSource.key === 'initiator_id') {
        augmentedCashflows = await this._findMembershipsFullNames(cashflows);
      } else if (this.selectedSource.key === 'team_id') {
        augmentedCashflows = this._peekSourcesNames(cashflows, 'team');
      } else if (this.selectedSource.key === 'label_lists') {
        augmentedCashflows = this._manageUnknownLabels(this._peekSourcesNames(cashflows, 'label'));
      } else {
        augmentedCashflows = cashflows;
      }
    }

    return augmentedCashflows;
  });

  getFormattedCashflowsTask = dropTask(async () => {
    let remainderLabel = this.intl.t('overview.all-the-rest');

    let cashflows = await this.getAugmentedCashflowsTask.perform(this.selectedPeriodCashflows);
    let previousCashflows = await this.getAugmentedCashflowsTask.perform(
      // @ts-expect-error
      this._getCashflowsData(this.args.cashflows, 1)
    );

    return formatDonutCashflows(
      remainderLabel,
      this._sortCashflows(cashflows),
      this.totalSelectedPeriodCashflows,
      this._sortCashflows(previousCashflows)
    );
  });

  @action
  // @ts-expect-error
  onSubmitAdvancedFilters(filters) {
    // @ts-expect-error
    this.args.onSubmitAdvancedFilters(filters, this.selectedPeriod, this.selectedSource);
  }

  // @ts-expect-error
  _updateFilters(filters) {
    // @ts-expect-error
    if (!this.isAdvancedFiltersMode) {
      this._persistFilters(filters);
      this._trackFiltersUpdate(this._advancedFilters, filters);
    }

    this._advancedFilters = filters;
  }

  // @ts-expect-error
  _lookupSource(persistedSource) {
    let defaultSource = this.sources[0];

    if (persistedSource.key === 'label_lists') {
      return this.sources.find(source => source.id === persistedSource.id) || defaultSource;
    } else {
      return this.sources.find(source => source.key === persistedSource.key) || defaultSource;
    }
  }

  // @ts-expect-error
  _lookupPeriod(persistedPeriod) {
    let defaultPeriod = this.periods[0];

    if (persistedPeriod.key === PERIOD_KEYS.CUSTOM_PERIOD) {
      return (
        {
          label: this.intl.t('overview.period-selection.custom-period'),
          startDate: new Date(persistedPeriod.startDate),
          endDate: new Date(persistedPeriod.endDate),
          key: PERIOD_KEYS.CUSTOM_PERIOD,
        } || defaultPeriod
      );
    } else {
      return this.periods.find(period => period.interval === persistedPeriod.key) || defaultPeriod;
    }
  }

  // @ts-expect-error
  _getCashflowsData(rawCashflows = [], index) {
    let cashflowsData;
    // @ts-expect-error
    let rawCashflowsData = rawCashflows[index]?.data;

    if (rawCashflowsData) {
      if (this.selectedSource.key === 'label_lists') {
        cashflowsData = rawCashflowsData?.label_lists_group?.find(
          // @ts-expect-error
          labelList => labelList.key === this.selectedSource.id
        )?.data?.labels_group;
      } else {
        cashflowsData = rawCashflowsData[`${this.selectedSource.key}_group`];
      }
    }

    return cashflowsData ? this._formatCashflows(cashflowsData) : [];
  }

  // @ts-expect-error
  _formatCashflows(cashflows) {
    let formattedCashflows = cashflows
      // @ts-expect-error
      .map(({ key: name, data: { amount_sum, amount_count: count } }) => ({
        name,
        value: parseFloat(amount_sum),
        count,
      }))
      .filter(Boolean);

    return formattedCashflows;
  }

  // @ts-expect-error
  _sortCashflows(cashflows) {
    // @ts-expect-error
    return cashflows.sort((a, b) => b.value - a.value);
  }

  // @ts-expect-error
  _peekSourcesNames(cashflows, source) {
    // @ts-expect-error
    return cashflows.map(cashflow => {
      if (cashflow.name !== 'uncategorized') {
        let record = this.store.peekRecord(source, cashflow.name);
        return {
          ...cashflow,
          name: record?.name,
        };
      } else {
        return cashflow;
      }
    });
  }

  /**
   * Move statistic data from cashflows with unknown/deleted labels to the `uncategorized` chunk
   * FIX: https://www.notion.so/qonto/For-1-user-Dashboard-outflows-by-label-is-crashing-when-time-period-increases-22aba2b7192e4879a08e0c1d2aa8c4d6
   * @param {[{name: string, value: number, count: number}]} cashflows
   * @returns mutated cashflows
   */
  // @ts-expect-error
  _manageUnknownLabels(cashflows) {
    // @ts-expect-error
    let uncategorized = cashflows.find(({ name }) => name === 'uncategorized');

    // aggregate data from all unknown labels (if name is undefined)
    let unknownLabelsData = cashflows.reduce(
      // @ts-expect-error
      ({ count, value }, cashflow) => {
        if (!cashflow.name) {
          count += cashflow.count;
          value += cashflow.value;
        }
        return { count, value };
      },
      { count: 0, value: 0 }
    );

    // report aggregated data to "uncategorized" section
    if (unknownLabelsData.count) {
      if (!uncategorized) {
        uncategorized = { name: '', value: 0, count: 0 };
        cashflows.push(uncategorized);
      }
      uncategorized.count += unknownLabelsData.count;
      uncategorized.value += unknownLabelsData.value;
    }

    //remove unknown label sections
    // @ts-expect-error
    return cashflows.filter(cashflow => cashflow.name);
  }

  // @ts-expect-error
  async _findMembershipsFullNames(cashflows) {
    // @ts-expect-error
    let uncategorizedItem = cashflows.find(({ name }) => name === 'uncategorized');
    // @ts-expect-error
    let cashflowsWithoutUncategorized = cashflows.filter(({ name }) => name !== 'uncategorized');

    // @ts-expect-error
    let fullNamesPromises = cashflowsWithoutUncategorized.map(({ name }) =>
      this.store.findRecord('membership', name)
    );
    let fullNames = await Promise.all(fullNamesPromises);

    // @ts-expect-error
    let augmentedCashflows = cashflowsWithoutUncategorized.map(cashflow => {
      let fullName = fullNames.find(({ id }) => id === cashflow.name).fullName;
      return {
        ...cashflow,
        name: fullName,
      };
    });

    if (uncategorizedItem) {
      augmentedCashflows.push(uncategorizedItem);
    }

    return augmentedCashflows;
  }

  // @ts-expect-error
  _openUpsellOverviewCarousel(key) {
    this.segment.track('upsell_dashboard_clicked', {
      widget_type: this.trackingWidgetType,
      upsell_type: key === 'team_id' ? 'group_by_team' : 'group_by_custom_label',
    });

    this.flowLinkManager.transitionTo({
      name: 'subscription-change',
      stepId: 'plans',
      queryParams: { upsellTrigger: 'advancedDashboard' },
    });
  }

  // @ts-expect-error
  _persistFilters(filters) {
    // @ts-expect-error
    safeLocalStorage.setItem(this.filtersStorageKey, JSON.stringify({ filters }));
  }

  // @ts-expect-error
  _trackFiltersUpdate(previousFilters, currentFilters) {
    let isFiltersUpdate = JSON.stringify(previousFilters) !== JSON.stringify(currentFilters);
    let updateType = 'updated';

    if (isFiltersUpdate) {
      if (!currentFilters) {
        updateType = 'removed';
      } else if (!previousFilters) {
        updateType = 'activated';
      }

      this.segment.track('dashboard_widget_update', {
        widget_type: this.trackingWidgetType,
        advanced_filters: updateType,
      });
    }
  }

  @action
  reload() {
    this.onUpdateTask.perform(this.selectedPeriod, this.advancedFilters).catch(ignoreCancelation);
  }

  selectSourceTask = dropTask(async source => {
    if (source.discover) {
      return this._openUpsellOverviewCarousel(source.key);
    }
    this.selectedSource = source;
    await this.onUpdateTask.perform(this.selectedPeriod, this.advancedFilters);

    this.segment.track('dashboard_widget_update', {
      widget_type: this.trackingWidgetType,
      // @ts-expect-error
      group_by_set_to: trackingEvents[source.key],
    });
  });

  selectPeriodTask = dropTask(async (period, closeDropdown) => {
    closeDropdown?.();
    this.selectedPeriod = period;
    await this.onUpdateTask.perform(period, this.advancedFilters);

    this.segment.track('dashboard_widget_update', {
      widget_type: this.trackingWidgetType,
      // @ts-expect-error
      period_set_to: trackingEvents[period.interval] || PERIOD_KEYS.CUSTOM_PERIOD,
    });
  });

  selectFiltersTask = dropTask(async filters => {
    if (filters !== null) {
      this._advancedFilters = filters;
      await this.onUpdateTask.perform(this.selectedPeriod, filters).catch(ignoreCancelation);
    }
  });

  selectBankAccountsTask = dropTask(async () => {
    await this.onUpdateTask
      .perform(this.selectedPeriod, this.advancedFilters)
      .catch(ignoreCancelation);
  });

  setAdvancedFiltersTask = dropTask(async () => {
    let advancedFiltersModal = this.modals.open(
      'overview/filters/donut',
      {
        isFullScreenModal: true,
        // @ts-expect-error
        bankAccounts: this.args.bankAccounts,
        advancedFilters: this.advancedFilters,
        // @ts-expect-error
        side: this.args.side,
        // @ts-expect-error
        widgetTitle: this.args.title,
      },
      { focusTrapOptions: { clickOutsideDeactivates: false } }
    );

    try {
      let widgetSettings = await advancedFiltersModal;

      // @ts-expect-error
      if (widgetSettings) {
        let { filters, period, source } = widgetSettings;

        if (period) {
          this.selectedPeriod = period;
        }
        if (source) {
          this.selectedSource = source;
        }
        this._updateFilters(filters);
        this.onUpdateTask.perform(period, filters).catch(ignoreCancelation);
      }
    } catch (error) {
      ignoreCancelation(error);
    } finally {
      // @ts-expect-error
      if (!advancedFiltersModal.result) {
        // @ts-expect-error
        advancedFiltersModal.close();
      }
    }
  });

  onUpdateTask = dropTask(async (period = this.selectedPeriod, filters) => {
    let aggregations;

    if (this.selectedSource.key === 'label_lists') {
      aggregations = [
        {
          name: 'label_lists_group',
          type: 'term',
          data: {
            property: 'label_lists.id',
          },
          aggregations: [
            {
              name: 'labels_group',
              type: 'term',
              data: {
                property: 'label_lists.labels',
                missing_value_key: 'uncategorized',
              },
            },
          ],
        },
      ];
    } else {
      aggregations = [
        {
          name: `${this.selectedSource.key}_group`,
          type: 'term',
          data: {
            property: `${this.selectedSource.key}`,
            missing_value_key: 'uncategorized',
          },
        },
      ];
    }

    let comparisonPeriod;
    if (this.selectedPeriod.interval) {
      comparisonPeriod = { interval: `comparison_for_${this.selectedPeriod.interval}` };
    } else {
      let { startDate, endDate } = this.selectedPeriod;

      let gap = dayjs(endDate).diff(startDate, 'hour');
      let comparisonEndDate = dayjs(startDate).subtract(1, 'day').endOf('day').toDate();
      let comparisonStartDate = dayjs(comparisonEndDate)
        .subtract(gap, 'hour')
        .startOf('day')
        .toDate();

      if (!validatePeriod(comparisonStartDate, comparisonEndDate)) {
        comparisonPeriod = undefined;
      } else {
        comparisonPeriod = { startDate: comparisonStartDate, endDate: comparisonEndDate };
      }
    }

    // @ts-expect-error
    await this.args.onUpdate?.(
      aggregations,
      period,
      // @ts-expect-error
      this.args.side,
      filters,
      this.bankAccounts,
      comparisonPeriod
    );
    return await this.getFormattedCashflowsTask.perform();
  });

  triggerRerenderTask = restartableTask(async () => {
    /**
     * We are not setting didComponentMount to false in the else block here the way we do in the
     * other components since this one never really re-renders as opposed to the others.
     */
    if (!this.didComponentMount) {
      this.didComponentMount = true;
    } else {
      await this.selectBankAccountsTask.perform();
    }
    // @ts-expect-error
    return this.args.bankAccounts;
  });

  // @ts-expect-error
  lastBankAccount = trackedTask(this, this.triggerRerenderTask, () => [this.args.bankAccounts]);
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Overview::Widgets::Donut': typeof OverviewWidgetsDonutComponent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './transactions-list.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { task } from 'ember-concurrency';

import {
  CLAIM_OUTDATED_TRESHOLD_ITALIAN_FRAUD,
  CLAIM_TYPE_OUTDATED_THRESHOLDS,
  CLAIM_TYPES,
  DEFAULT_SORT_BY,
  // @ts-expect-error
} from 'qonto/constants/transactions';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

interface FlowsCardChargebackTransactionsBulkSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class FlowsCardChargebackTransactionsBulkSelectionComponent extends Component<FlowsCardChargebackTransactionsBulkSelectionSignature> {
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare sentry: Services['sentry'];
  @service declare organizationManager: Services['organizationManager'];

  @tracked isError = false;

  constructor(owner: unknown, args: FlowsCardChargebackTransactionsBulkSelectionSignature['Args']) {
    super(owner, args);
    this.fetchDataTask.perform().catch(ignoreCancelation);
  }

  get disclaimerBody() {
    // @ts-expect-error
    let { claimType } = this.args.context;

    // Bank of Italy requirement
    if (this.isItalianFraudClaim) {
      return this.intl.t('dispute-flow.transactions-selection.bulk-selection.disclaimer.body', {
        learnMoreLink: this.intl.t('dispute-flow.transactions-selection.bulk-selection.learn-more'),
        validityPeriod: this.intl.t(
          'dispute-flow.transactions-selection.bulk-selection.disclaimer.italian-validity-days'
        ),
      });
    }
    return this.intl.t('dispute-flow.transactions-selection.bulk-selection.disclaimer.body', {
      learnMoreLink: this.intl.t('dispute-flow.transactions-selection.bulk-selection.learn-more'),
      validityPeriod:
        claimType === CLAIM_TYPES.fraud
          ? this.intl.t(
              'dispute-flow.transactions-selection.bulk-selection.disclaimer.validity-weeks'
            )
          : this.intl.t(
              'dispute-flow.transactions-selection.bulk-selection.disclaimer.validity-days'
            ),
    });
  }

  get transactions() {
    // @ts-expect-error
    return this.fetchDataTask.last.value?.transactions;
  }

  get hasOldTransactions() {
    // @ts-expect-error
    return this.fetchDataTask.last.value?.hasOldTransactions;
  }

  get isLoading() {
    return this.fetchDataTask.isRunning;
  }

  get isItalianFraudClaim() {
    // @ts-expect-error
    let { claimType } = this.args.context;
    return claimType === CLAIM_TYPES.fraud && this.organizationManager.organization.isItalian;
  }

  fetchDataTask = task(async () => {
    // @ts-expect-error
    let { setShouldShowFooter } = this.args;

    try {
      // @ts-expect-error
      let { card, claimType, transaction } = this.args.context;
      let organizationId = this.organizationManager.organization.id;
      let [property, direction] = DEFAULT_SORT_BY.split(':');

      // @ts-expect-error
      let { transactions } = await this.store.adapterFor('transaction').search({
        organization_id: organizationId,
        search: '',
        sort: { property, direction },
        pagination: { page: 1, per_page: 500 },
        filter_group: {
          conditional: 'and',
          expressions: [
            {
              property: 'card_id',
              values: [card.id],
              operator: 'in',
            },
          ],
        },
      });

      this.isError = false;
      setShouldShowFooter?.(true);

      let filteredTransactions = transactions;

      if (claimType === CLAIM_TYPES.commercial) {
        filteredTransactions = transactions.filter(
          // @ts-expect-error
          t => t.counterpartyName === transaction.counterpartyName
        );
      } else if (claimType === CLAIM_TYPES.atm) {
        // @ts-expect-error
        filteredTransactions = transactions.filter(t => t.activityTag === 'atm');
      }

      let threshold = this.isItalianFraudClaim
        ? CLAIM_OUTDATED_TRESHOLD_ITALIAN_FRAUD // Bank of Italy requirement
        : CLAIM_TYPE_OUTDATED_THRESHOLDS[claimType];

      // @ts-expect-error
      let relevantTransactions = filteredTransactions.filter(ft => {
        let displayedDay = dayjs(ft?.displayAt);
        return dayjs().diff(displayedDay, 'day') <= threshold;
      });

      let hasOldTransactions = relevantTransactions.length < filteredTransactions.length;

      return { transactions: relevantTransactions, hasOldTransactions };
    } catch {
      this.isError = true;
      setShouldShowFooter?.(false);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::ChargebackClaim::TransactionsBulkSelection::TransactionsList': typeof FlowsCardChargebackTransactionsBulkSelectionComponent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './roles.hbs'; */
/* eslint-disable @qonto/no-import-roles-constants */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { INVITATION_TYPES, ROLES } from 'qonto/constants/membership';

interface FlowsMemberProvidersRolesSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsMemberProvidersRolesComponent extends Component<FlowsMemberProvidersRolesSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare upsellManager: Services['upsellManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];

  get currentUserRole() {
    // @ts-expect-error
    return this.args.context.member.role;
  }

  get isEditingMember() {
    // @ts-expect-error
    return this.args.context.isEditingMember;
  }

  get hasReachedUserLimit() {
    return this.subscriptionManager.hasReachedUserLimit;
  }

  get isAdvancedManager() {
    return [
      'access_bank_accounts_statements',
      'access_checks',
      'access_direct_debits',
      'manage_bookkeepings',
    ].some(permission => {
      // @ts-expect-error
      return this.args.context.member.customPermissions.groups[permission] === true;
    });
  }

  get chargedRoles() {
    if (this.hasReachedUserLimit && this.abilities.can('create paid members members')) {
      return [ROLES.ADMIN, ROLES.MANAGER, ROLES.EMPLOYEE];
    }

    return [];
  }

  get isGuest() {
    // @ts-expect-error
    let { invitationType } = this.args.context;
    return invitationType === INVITATION_TYPES.GUEST;
  }

  get lockedRoles() {
    let areAllRolesBlocked =
      !this.chargedRoles.length &&
      this.hasReachedUserLimit &&
      this.abilities.can('access accountant-access') &&
      this.abilities.cannot('add admin only members');

    if (areAllRolesBlocked) {
      return [ROLES.ADMIN, ROLES.MANAGER, ROLES.EMPLOYEE];
    }

    if (this.abilities.can('add admin only member')) {
      return [ROLES.EMPLOYEE];
    }

    if (this.abilities.cannot('access manager role feature members')) {
      return [ROLES.MANAGER, ROLES.ADVANCED_MANAGER];
    }

    if (this.abilities.cannot('access advanced manager role feature members')) {
      return [ROLES.ADVANCED_MANAGER];
    }

    return [];
  }

  get employeeDoCopy() {
    if (this.abilities.can('use mileages request')) {
      return this.intl.t('invitation-flow.roles.employee.do-expense-reports-mileage');
    }

    if (this.abilities.can('use expense reports request')) {
      return this.intl.t('invitation-flow.roles.employee.do-expense-reports');
    }

    return this.intl.t('invitation-flow.roles.employee.do');
  }

  get roles() {
    let cardIcon = 'icon_card_outlined';

    let roles = [
      {
        key: ROLES.EMPLOYEE,
        name: this.intl.t('roles.employee'),
        illustration: {
          active: '/illustrations/invite/color/employee.svg',
          disabled: '/illustrations/invite/disabled/employee.svg',
        },
        access: this.intl.t('invitation-flow.roles.employee.access'),
        description: [
          {
            icon: 'icon_checkmark_outlined',
            text: this.employeeDoCopy,
          },
          { icon: cardIcon, text: this.intl.t('invitation-flow.roles.employee.cards') },
          {
            icon: 'icon_eye_hidden_outlined',
            text: this.intl.t('invitation-flow.roles.employee.view'),
          },
        ],
        available: !this.lockedRoles?.includes(ROLES.EMPLOYEE),
        currentRole: this.isEditingMember && this.currentUserRole === ROLES.EMPLOYEE,
        disabled: false,
      },
      {
        key: ROLES.REPORTING,
        name: this.intl.t('roles.reporting'),
        illustration: {
          active: '/illustrations/invite/color/accountant.svg',
          disabled: '/illustrations/invite/disabled/accountant.svg',
        },
        access: this.intl.t('invitation-flow.roles.reporting.access'),
        description: [
          {
            icon: 'icon_checkmark_outlined',
            text: this.intl.t('invitation-flow.roles.reporting.do'),
          },
          {
            icon: 'icon_eye_outlined',
            text: this.intl.t('invitation-flow.roles.reporting.view'),
          },
        ],
        disclaimer: this.intl.t('members.invite.access-rights.reporting.disclaimer'),
        available: !this.lockedRoles?.includes(ROLES.REPORTING),
        currentRole: this.isEditingMember && this.currentUserRole === ROLES.REPORTING,
        disabled: this.hasReachedAccountantLimit,
      },
      {
        key: ROLES.MANAGER,
        name: this.intl.t('roles.standard-manager'),
        illustration: {
          active: '/illustrations/invite/color/basic-manager.svg',
          disabled: '/illustrations/invite/disabled/basic-manager.svg',
        },
        access: this.intl.t('invitation-flow.roles.manager.access'),
        description: [
          {
            icon: 'icon_checkmark_outlined',
            text: this.intl.t('invitation-flow.roles.manager.do'),
          },
          {
            icon: cardIcon,
            text: this.intl.t('invitation-flow.roles.manager.cards'),
          },
          {
            icon: 'icon_eye_outlined',
            text: this.intl.t('invitation-flow.roles.manager.view'),
          },
        ],
        available: !this.lockedRoles?.includes(ROLES.MANAGER),
        currentRole:
          this.isEditingMember && this.currentUserRole === ROLES.MANAGER && !this.isAdvancedManager,
        disabled: false,
        hasTrial: this.upsellManager.hasAvailableFreeTrial('advanced_manager_permission'),
      },
      {
        key: ROLES.ADVANCED_MANAGER,
        name: this.intl.t('roles.advanced-manager'),
        illustration: {
          active: '/illustrations/invite/color/manager.svg',
          disabled: '/illustrations/invite/disabled/manager.svg',
        },
        access: this.intl.t('invitation-flow.roles.advanced-manager.access'),
        description: [
          {
            icon: 'icon_checkmark_outlined',
            text: this.intl.t('invitation-flow.roles.advanced-manager.do'),
          },
          {
            icon: cardIcon,
            text: this.intl.t('invitation-flow.roles.advanced-manager.cards'),
          },
          {
            icon: 'icon_eye_outlined',
            text: this.intl.t('invitation-flow.roles.advanced-manager.view'),
          },
        ],
        available: !this.lockedRoles?.includes(ROLES.ADVANCED_MANAGER),
        hasTrial: this.upsellManager.hasAvailableFreeTrial('advanced_manager_permission'),
        currentRole:
          this.isEditingMember && this.currentUserRole === ROLES.MANAGER && this.isAdvancedManager,
        disabled: false,
      },
      {
        key: ROLES.ADMIN,
        name: this.intl.t('roles.admin'),
        illustration: {
          active: '/illustrations/invite/color/admin.svg',
          disabled: '/illustrations/invite/disabled/admin.svg',
        },
        access: this.intl.t('invitation-flow.roles.admin.access'),
        description: [
          {
            icon: 'icon_checkmark_outlined',
            text: this.abilities.can('add admin only member')
              ? this.intl.t('invitation-flow.roles.admin.do-solo')
              : this.intl.t('invitation-flow.roles.admin.do'),
          },
          {
            icon: cardIcon,
            text: this.abilities.can('add admin only member')
              ? this.intl.t('invitation-flow.roles.admin.cards-solo')
              : this.intl.t('invitation-flow.roles.admin.cards'),
          },
          {
            icon: 'icon_eye_outlined',
            text: this.intl.t('invitation-flow.roles.admin.view'),
          },
        ],
        available: !this.lockedRoles?.includes(ROLES.ADMIN),
        currentRole: this.isEditingMember && this.currentUserRole === ROLES.ADMIN,
        disabled: false,
      },
    ];

    // @ts-expect-error
    if (this.args.context.invitationType === INVITATION_TYPES.STAFF) {
      roles = roles.filter(role =>
        [ROLES.EMPLOYEE, ROLES.MANAGER, ROLES.ADVANCED_MANAGER, ROLES.ADMIN].includes(role.key)
      );
    }

    // @ts-expect-error
    if (this.args.context.isGSAInviteTeamMember) {
      roles = roles.filter(role =>
        [ROLES.EMPLOYEE, ROLES.MANAGER, ROLES.ADVANCED_MANAGER].includes(role.key)
      );
    }

    if (this.abilities.can('add admin only member')) {
      let availableRoles = this.isGuest ? [ROLES.REPORTING] : [ROLES.EMPLOYEE, ROLES.ADMIN];

      roles = roles.filter(role => availableRoles.includes(role.key));
    }

    return roles;
  }

  get hasReachedAccountantLimit() {
    return this.subscriptionManager.hasReachedAccountantLimit;
  }

  get isManager() {
    return this.organizationManager.membership.role === ROLES.MANAGER;
  }

  get isGbR() {
    return this.organizationManager.organization.isGbR;
  }

  get selectedRole() {
    // @ts-expect-error
    if (this.args.context.role) {
      // @ts-expect-error
      return this.args.context.role;
    }

    // @ts-expect-error
    if (this.args.context.invitationType === INVITATION_TYPES.GUEST) {
      return this.roles.find(({ key }) => key === ROLES.REPORTING);
    }

    if (this.isGbR) {
      return this.roles.find(({ key }) => key === ROLES.ADMIN);
    }

    if (this.isManager) {
      return this.roles.find(({ key }) => key === ROLES.EMPLOYEE);
    }

    return null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Member::Providers::Roles': typeof FlowsMemberProvidersRolesComponent;
  }
}

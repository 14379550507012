export default {
  "container": "cPa",
  "form-container": "cPH",
  "title": "cPO",
  "title-border": "cPm",
  "tabs-container": "cPp",
  "preview-container": "cPx",
  "email-preview-container": "cPw",
  "close-button": "cPN btn btn--icon-only btn--tertiary btn--large"
};

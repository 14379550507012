export default {
  "sidebar": "clQ",
  "top-section": "cle",
  "title": "clB",
  "primary-action": "clE",
  "edit-icon": "cld",
  "close-icon": "clI",
  "box-wrapper": "clt",
  "bottom-action": "clM",
  "related-invoices": "clP",
  "related-invoices-title": "cll mb-16 caption-bold",
  "box": "clX",
  "box-element": "clC",
  "cancel-text": "clk",
  "box-header": "clJ",
  "row": "cls",
  "greyed-out": "clF",
  "struck-through": "clg",
  "share-mandate-link": "clD"
};

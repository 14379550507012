export default {
  "invitations-password-form": "cMA",
  "mobile-mode": "cMY",
  "logo": "cMh",
  "header": "cMf",
  "controls": "cMK",
  "footer": "cMG",
  "submit-cta": "cMr",
  "subtitle": "cMb"
};

export default {
  "journey": "ccx",
  "content": "ccw",
  "distance": "ccN",
  "google-link-container": "ccv",
  "link-icon": "cco",
  "continue-btn": "ccn",
  "origin": "cci",
  "input-width": "ccA"
};

export default {
  "row": "csh",
  "terminated": "csf",
  "status-avatar": "csK",
  "amount": "csG body-1",
  "item-info": "csr",
  "date-cell": "csb",
  "cell": "csy body-2",
  "status-icon": "csU",
  "amount-cell": "csj",
  "item-name-cell": "csW",
  "item-name-container": "csu"
};

export default {
  "sidebar-section": "ctX body-2",
  "sidebar-section-header": "ctC body-1 mb-4",
  "providers-logos": "ctk",
  "risks": "ctJ",
  "tag": "cts tag",
  "external-link-icon": "ctF",
  "disclaimer": "ctg",
  "divider": "ctD"
};

export default {
  "invite-review": "us",
  "invite-review-info": "uF",
  "invite-review-info--invitee": "ug",
  "disclaimer-button": "uD",
  "info-block": "uV",
  "ev": "uL",
  "tooltip": "uT",
  "description": "uz"
};

/* import __COLOCATED_TEMPLATE__ from './collapsible-form.hbs'; */
import Component from '@glimmer/component';

import { ContentCategory } from 'qonto/react/receivable-invoices/components/invoice-settings/content-category';

interface InvoiceSettingsCollapsibleFormSignature {
  // The arguments accepted by the component
  Args: {
    settings: any;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSettingsCollapsibleFormComponent extends Component<InvoiceSettingsCollapsibleFormSignature> {
  contentCategory = ContentCategory;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::CollapsibleForm': typeof InvoiceSettingsCollapsibleFormComponent;
  }
}

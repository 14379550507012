export default {
  "container": "cBo",
  "container--collapsed": "cBn",
  "spin": "cBi",
  "cta-tooltip": "cBA",
  "cta--collapsed": "cBY",
  "cta--expanded": "cBh",
  "collapsed-svg-icon": "cBf",
  "expanded-svg-icon": "cBK",
  "generate-logo-cta": "cBG",
  "badge": "cBr",
  "scaleDown": "cBb",
  "fadeIn": "cBy",
  "textRiseButton": "cBU",
  "cta-description": "cBj",
  "textRiseDescription": "cBW"
};

/* import __COLOCATED_TEMPLATE__ from './documents.hbs'; */
import Component from '@glimmer/component';

interface PaymentLinksSidebarDocumentsSignature {
  // The arguments accepted by the component
  Args: {
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PaymentLinksSidebarDocuments extends Component<PaymentLinksSidebarDocumentsSignature> {
  get hasDocument() {
    // @ts-expect-error
    return Boolean(this.args.resource.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PaymentLinks::Sidebar::Documents': typeof PaymentLinksSidebarDocuments;
  }
}

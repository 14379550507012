export default {
  "checks": "be",
  "wrapper": "bB",
  "form": "bE",
  "title": "bd",
  "wrapper-body": "bI",
  "aside": "bt",
  "steps-container": "bM",
  "step": "bP",
  "container-dot": "bl",
  "text": "bX",
  "text-title": "bC",
  "text-desc": "bk",
  "start-button": "bJ"
};

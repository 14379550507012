export default {
  "wrapper": "cZR",
  "wrapper-revamp": "cZQ",
  "container": "cZe",
  "container-revamp": "cZB",
  "box-wrapper": "cZE",
  "warning-label": "cZd",
  "subtitle-container": "cZI",
  "info-container": "cZt",
  "card": "cZM",
  "disclaimer": "cZP",
  "list": "cZl",
  "list-item": "cZX body-2",
  "total": "cZC"
};

export default {
  "navigation-dropdown": "cJP",
  "expanded": "cJl",
  "trigger": "cJX",
  "trigger-title-icon": "cJC",
  "collapsed": "cJk",
  "responsive": "cJJ",
  "logo": "cJs",
  "active": "cJF",
  "trigger-title": "cJg",
  "content": "cJD",
  "content-container": "cJV",
  "dropdown-icon": "cJL",
  "link": "cJT"
};

export default {
  "bucket-item": "cdv",
  "button": "cdo",
  "content": "cdn",
  "bucket-icon": "cdi",
  "bucket-title-container": "cdA",
  "bucket-new-badge": "cdY",
  "bucket-title": "cdh",
  "arrow-icon": "cdf"
};

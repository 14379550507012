export default {
  "wrapper": "cVP",
  "cashflow-status": "cVl",
  "status": "cVX",
  "estimated": "cVC",
  "vat-due-amount": "cVk title-3",
  "vat-tooltip": "cVJ",
  "icon-info": "cVs",
  "charts-wrapper": "cVF",
  "chart-error": "cVg",
  "chart-controls": "cVD",
  "amount-loading": "cVV"
};

export default {
  "step-container": "ub",
  "subtitle": "uy",
  "step-container-content": "uU",
  "continue-button": "uj",
  "period-select": "uW",
  "period-dropdown": "uu",
  "readonly": "cSS",
  "period-error": "cSc",
  "toggle-options-container": "cSq",
  "receipt-code-toggle-input": "cSZ"
};

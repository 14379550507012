export default {
  "card": "cXF",
  "focus": "cXg",
  "title-format": "cXD",
  "header": "cXV",
  "headings": "cXL",
  "title": "cXT title-4",
  "main": "cXz title-3",
  "footer": "cXa"
};

/* import __COLOCATED_TEMPLATE__ from './emitter.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DateField } from '@repo/design-system-kit';

import { ChecksImage } from 'qonto/react/components/checks/image';

interface FlowsCheckEmitterSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsCheckEmitterComponent extends Component<FlowsCheckEmitterSignature> {
  @service declare segment: Services['segment'];
  dateField = DateField;
  checksImage = ChecksImage;

  @tracked highlightEmitterName = false;
  @tracked highlightEmittedDate = false;
  @tracked showNameErrorMessage = false;
  @tracked showDateErrorMessage = false;

  get date() {
    // @ts-expect-error
    let date = this.args.context.check.emittedDate;
    return date ? parseDate(date) : null;
  }

  @action
  // @ts-expect-error
  onDateChange(value) {
    // @ts-expect-error
    this.args.context.check.emittedDate = value?.toString();
  }

  get emitterNameErrorMessage() {
    // @ts-expect-error
    let { emitterName: emitterNameValidation } = this.args.context.check.validations.attrs;

    return emitterNameValidation.isInvalid && this.showNameErrorMessage
      ? emitterNameValidation.message
      : null;
  }

  get emittedDateErrorMessage() {
    // @ts-expect-error
    let { emittedDate: emittedDateValidation } = this.args.context.check.validations.attrs;

    return emittedDateValidation.isInvalid && this.showDateErrorMessage
      ? emittedDateValidation.message
      : null;
  }

  get hasError() {
    // @ts-expect-error
    let { check } = this.args.context;
    let { emittedDate: emittedDateValidation, emitterName: emitterNameValidation } =
      check.validations.attrs;

    return !check.emittedDate || emitterNameValidation.isInvalid || emittedDateValidation.isInvalid;
  }

  @action
  // @ts-expect-error
  handleGoToDetailsPage(event) {
    event.preventDefault();
    // @ts-expect-error
    let { emittedDate: emittedDateValidation } = this.args.context.check.validations.attrs;

    if (emittedDateValidation.isInvalid) this.showDateErrorMessage = true;
    if (this.hasError) return;

    this.segment.track('checks_deposit_issuer_and_date_submitted');
    // @ts-expect-error
    return this.args.transitionToNext();
  }

  @action
  handleEmitterNameFocusIn() {
    this.highlightEmitterName = true;
  }

  @action
  handleEmitterNameFocusOut() {
    this.highlightEmitterName = false;
    this.showNameErrorMessage = true;
  }

  @action
  handleEmitterNameKeyDown() {
    this.showNameErrorMessage = false;
  }

  @action
  // @ts-expect-error
  handleEmitterNameUpdate(value) {
    // @ts-expect-error
    this.args.context.check.emitterName = value;
  }

  @action
  // @ts-expect-error
  handleEmittedDateFocusChange(val) {
    this.highlightEmittedDate = val;
    if (!val && this.hasError) {
      this.showDateErrorMessage = true;
    }
  }

  @action
  handleEmittedDateKeyDown() {
    this.showDateErrorMessage = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Check::Emitter': typeof FlowsCheckEmitterComponent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './organization-card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { SimpleValue } from 'qonto/react/components/organization-profile/simple-value';
import { Value } from 'qonto/react/components/organization-profile/value';
// @ts-expect-error
import { ORIGIN } from 'qonto/routes/flows/setup/subscription/close';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface OrganizationCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class OrganizationCard extends Component<OrganizationCardSignature> {
  simpleValue = SimpleValue;

  value = Value;

  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];

  origin = ORIGIN.COMPANY_PROFILE;

  get address() {
    // @ts-expect-error
    let ocr = this.args.kycKybUpdateOrganizationChangeRequest;

    if (!ocr.addressCountry || !ocr.addressFirstLine || !ocr.addressZipCode || !ocr.addressCity) {
      return false;
    }

    // @ts-expect-error
    let country = this.intl.formatCountry(ocr.addressCountry);
    return `${ocr.addressFirstLine}, ${ocr.addressZipCode} ${ocr.addressCity} ${country}`;
  }

  get legalFormValue() {
    // @ts-expect-error
    let legalCode = this.args.kycKybUpdateOrganizationChangeRequest.legalCode;
    // @ts-expect-error
    let legalCountry = this.args.kycKybUpdateOrganizationChangeRequest
      ?.get('kycKybUpdateProcess')
      ?.get('organization')
      ?.get('legalCountry');

    if (legalCode && legalCountry) {
      try {
        let companyTypeOption = this.intl.t(
          `organizations.profile.company_profile.company_type_option.${legalCountry}.${legalCode}`
        );

        return companyTypeOption;
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        return null; // mitigation for incorrect legalCountry vs legalCode
      }
    }
    return null;
  }

  get isGermanSoloLegalFormOrGbr() {
    return (
      // @ts-expect-error
      this.args.kycKybUpdateOrganizationChangeRequest.isGbR ||
      // @ts-expect-error
      this.args.kycKybUpdateOrganizationChangeRequest.isGermanFreelancer
    );
  }

  get legalNumberLabel() {
    // @ts-expect-error
    let legalCountry = this.args.kycKybUpdateOrganizationChangeRequest.get(
      'kycKybUpdateProcess.organization.legalCountry'
    );
    return this.isGermanSoloLegalFormOrGbr
      ? this.intl.t(
          'organizations.profile.company_profile.organization_info.legal_number_solo_and_gbr'
        )
      : this.intl.t('organizations.profile.company_profile.organization_info.legal_number', {
          country: legalCountry,
        });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationProfile::OrganizationCard': typeof OrganizationCard;
  }
}

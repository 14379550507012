export default {
  "guests-loading": "cEG",
  "header": "cEr",
  "body": "cEb",
  "members": "cEy",
  "title": "cEU caption-bold",
  "placeholder-container": "cEj",
  "icon": "cEW",
  "details": "cEu"
};

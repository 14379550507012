export default {
  "item": "cCj",
  "selected": "cCW",
  "hover-revised": "cCu",
  "asset-container": "ckS",
  "asset": "ckc",
  "revoked-icon": "ckq",
  "pending-icon": "ckZ",
  "revoked": "ckR",
  "revised-revoke": "ckQ",
  "pending": "cke",
  "revised-pending": "ckB",
  "invitable": "ckE",
  "info": "ckd",
  "name": "ckI",
  "email": "ckt",
  "actions": "ckM",
  "invitable-member-delete": "ckP",
  "invitable-member-invite": "ckl"
};

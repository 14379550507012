/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { Option } from 'qonto/react/cards/components/flows/choose-card/option';

interface CardStepsChooseCardSelectorSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class CardStepsChooseCardSelector extends Component<CardStepsChooseCardSelectorSignature> {
  option = Option;

  @service declare intl: Services['intl'];
  @service declare abilities: Services['abilities'];

  get isMetalCard() {
    // @ts-expect-error
    return this.args.cardLevel === CARD_LEVELS.METAL;
  }

  get insuranceLinkLabel() {
    // @ts-expect-error
    let { cardLevel } = this.args;

    switch (cardLevel) {
      case CARD_LEVELS.METAL:
        return this.intl.t('cards.steps.choose-card.features.insurance.metal');
      case CARD_LEVELS.PLUS:
        return this.intl.t('cards.steps.choose-card.features.insurance.plus');
      case CARD_LEVELS.STANDARD:
        return this.intl.t('cards.steps.choose-card.features.insurance.standard');
      default:
        return null;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCard::Selector': typeof CardStepsChooseCardSelector;
  }
}

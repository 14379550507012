export default {
  "name-wrapper": "czD",
  "preset-name": "czV",
  "invoices-entry-period-container": "czL",
  "transactions-entry-period-container": "czT",
  "period-wrapper": "czz",
  "period-btn": "cza",
  "cancel-placeholder": "czH",
  "period-cancel-btn": "czO",
  "transactions-entry-container": "czm",
  "invoices-entry-container": "czp",
  "dropdown": "czx",
  "dropdown-opened": "czw",
  "dropdown-closed": "czN"
};

export default {
  "step-container": "Uc",
  "content": "Uq",
  "section": "UZ",
  "section-content": "UR",
  "section-content-text": "UQ",
  "section-illustration": "Ue",
  "close-form-cta": "UB",
  "bank-account-select": "UE"
};

/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { Success } from 'qonto/react/financing/components/flows/pay-later/application/success';

interface FlowsFinancingPayLaterApplicationSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsFinancingPayLaterApplicationSuccessComponent extends Component<FlowsFinancingPayLaterApplicationSuccessSignature> {
  success = Success;

  @action
  goToTransfers() {
    // @ts-expect-error
    this.args.transitionToFlow({
      flowName: 'pay-later-transfer',
      stepId: 'invoice-upload',
      queryParams: {
        origin: 'pay-later-application.success',
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Application::Success': typeof FlowsFinancingPayLaterApplicationSuccessComponent;
  }
}

export default {
  "label-wrapper": "rV body-1",
  "avatar": "rL",
  "container": "rT",
  "transaction-info": "rz",
  "secondary": "ra",
  "amount-container": "rH",
  "amount": "rO",
  "disabled": "rm",
  "checkbox": "rp",
  "disclaimer": "rx"
};

export default {
  "container": "AP",
  "numbering": "Al",
  "sideview": "AX",
  "form": "AC",
  "numbering-fields": "Ak",
  "row": "AJ",
  "label-tooltip": "As",
  "tooltip-icon": "AF",
  "legend": "Ag",
  "next-invoice-number": "AD",
  "pdf-preview": "AV",
  "de-layout": "AL",
  "de-pdf-preview": "AT"
};

export default {
  "donut-chart-container": "cgM",
  "donut-chart-wrapper": "cgP",
  "chart": "cgl",
  "donut-chart-legend-container": "cgX caption",
  "donut-chart-legend-item-empty": "cgC",
  "donut-chart-legend-bullet-empty": "cgk",
  "donut-chart-legend-bullet": "cgJ",
  "donut-chart-legend-text-empty": "cgs",
  "donut-chart-legend-text": "cgF",
  "donut-chart-custom-label-uncategorized": "cgg",
  "custom-label-uncategorized-button": "cgD body-1",
  "donut-chart-legend": "cgV",
  "donut-chart-legend-item": "cgL",
  "donut-chart-legend-other-item": "cgT",
  "donut-chart-legend-uncategorized-item": "cgz",
  "donut-chart-legend-text-name": "cga",
  "tooltip": "cgH"
};

/* import __COLOCATED_TEMPLATE__ from './type-selection.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES } from 'qonto/constants/direct-debit-subscriptions';
import { apiBaseURL, billingNamespace } from 'qonto/constants/hosts';
import { TypeCard } from 'qonto/react/components/direct-debit-collections/type-card';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface FlowsDirectDebitCollectionsNewTypeSelectionSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsDirectDebitCollectionsNewTypeSelectionComponent extends Component<FlowsDirectDebitCollectionsNewTypeSelectionSignature> {
  @service declare sentry: Services['sentry'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare router: Services['router'];
  @service declare networkManager: Services['networkManager'];

  TypeCard = TypeCard;

  get hasRecurringInvoiceEnabled() {
    return this.subscriptionManager.hasFeature('recurring_invoices');
  }

  @action
  // @ts-expect-error
  onTypeSelect(type) {
    // @ts-expect-error
    this.args.context.directDebitSubscription.scheduleType =
      type === 'one-off'
        ? DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.ONE_OFF
        : DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES.MONTHLY;

    this.segment.track('incoming-direct-debit-flow_collection-type_selected', {
      collection_type: type,
    });

    // @ts-expect-error
    this.args.transitionToNext();
  }

  onRecurringInvoiceSelect = dropTask(async () => {
    this.segment.track('incoming-direct-debit-flow_collection-type_selected', {
      collection_type: 'recurring-invoices',
    });

    if (this.hasRecurringInvoiceEnabled) {
      this.router.transitionTo('invoice-subscriptions.new');
      return;
    }

    try {
      let {
        recommended_recurrence: recurrence,
        recommended_product: { code },
      } = await this.networkManager.request(
        `${apiBaseURL}/${billingNamespace}/subscriptions/upgrade_recommendation?feature_code=recurring_invoices`
      );

      // @ts-expect-error
      this.args.context.recurrence = recurrence;
      // @ts-expect-error
      this.args.context.addonCode = code;

      // @ts-expect-error
      this.args.pushFlow('addon-change', 'addons');
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::DirectDebitCollections::Subscription::TypeSelection': typeof FlowsDirectDebitCollectionsNewTypeSelectionComponent;
  }
}

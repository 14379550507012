export default {
  "members-loading": "ckA",
  "members-loading--ff": "ckY",
  "header": "ckh",
  "header-search-bar": "ckf",
  "body": "ckK",
  "members": "ckG",
  "placeholder-container": "ckr",
  "icon": "ckb",
  "details": "cky",
  "member-loading": "ckU",
  "member-loading--no-ff": "ckj",
  "body-members": "ckW",
  "body-details": "cku"
};

/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { DEFAULT_SORT_ORDER, NRC_PAYMENTS_SORT_BYS } from 'qonto/constants/sort';
import { SortIcon } from 'qonto/react/components/paginated-table';

interface NrcPaymentsHeaderSignature {
  // The arguments accepted by the component
  Args: {
    isCompleted?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class NrcPaymentsHeaderComponent extends Component<NrcPaymentsHeaderSignature> {
  sortIcon = SortIcon;

  SORT_BYS = NRC_PAYMENTS_SORT_BYS;

  get sortBy() {
    // @ts-expect-error
    let { sortBy } = this.args;
    return sortBy?.split(':');
  }

  get sortField() {
    return this.sortBy?.[0];
  }

  get sortOrder() {
    return this.sortBy?.[1] ?? DEFAULT_SORT_ORDER;
  }

  @action
  // @ts-expect-error
  handleSortBy(name) {
    // @ts-expect-error
    let { onSortByChanged } = this.args;
    let sortOrder = DEFAULT_SORT_ORDER;

    if (name === this.sortField) {
      // @ts-expect-error
      sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }

    return onSortByChanged?.(`${name}:${sortOrder}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Nrc::Table::Header': typeof NrcPaymentsHeaderComponent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './company-details-category.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface InvoiceSettingsFormCompanyDetailsCategorySignature {
  // The arguments accepted by the component
  Args: {
    settings: any;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSettingsFormCompanyDetailsCategoryComponent extends Component<InvoiceSettingsFormCompanyDetailsCategorySignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  get settings() {
    return this.args.settings;
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get legalCountry() {
    return this.organization.legalCountry;
  }

  get contactEmailErrorMessage() {
    let errors = this.settings.errors.get('contactEmail');

    if (errors.length === 0) return null;

    // @ts-expect-error
    if (errors.some(error => error.message === 'invalid')) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.new.tab-settings.errors.invalid-contact-email'
      );
    }
    return this.intl.t('receivable-invoices.invoice-creation.errors.required-field');
  }

  get vatNumberErrorMessage() {
    let errors = this.settings.errors.get('vatNumber');

    if (errors.length === 0) return null;

    // @ts-expect-error
    if (errors.some(error => error.message === 'invalid')) {
      return this.intl.t(
        'receivable-invoices.invoice-setting.settings-modal.general.company-details.vat-error-message'
      );
    }
  }

  get fullListDisclaimer() {
    let invoiceDisclaimer = {
      firstPart: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.part-1'
      ),
      href: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.faq-link',
        {
          legalCountry: this.legalCountry,
          faqUrl: this.zendeskLocalization.getLocalizedArticle(6211274),
        }
      ),
      textLink: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.faq-text-link'
      ),
      secondPart: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.part-2'
      ),
    };
    return invoiceDisclaimer;
  }

  @action
  handleEmailUpdate(email: string) {
    this.settings.contactEmail = email;
    this.segment.track('invoice-creation_additional-details_email_edited');
  }

  @action
  handleVatNumberUpdate(vatNumber: string) {
    this.settings.vatNumber = vatNumber;
    this.segment.track('invoice-creation_additional-details_vat-number_edited');
  }

  @action
  handleDistrictCourtUpdate(districtCourt: string) {
    this.settings.districtCourt = districtCourt;
    this.segment.track('invoice-creation_additional-details_district-court_edited');
  }

  @action
  handleCompanyLeadershipUpdate(companyLeadership: string) {
    this.settings.companyLeadership = companyLeadership;
    this.segment.track('invoice-creation_additional-details_company-leadership_edited');
  }

  @action
  handleTaxNumberUpdate(taxNumber: string) {
    this.settings.taxNumber = taxNumber;
    this.segment.track('invoice-creation_additional-details_tax-number_edited');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSettings::Form::CompanyDetailsCategory': typeof InvoiceSettingsFormCompanyDetailsCategoryComponent;
  }
}

export default {
  "y-axis-placeholder": "cFW",
  "y-axis-navigable": "cFu",
  "y-axis-tick": "cgS",
  "x-axis-placeholder": "cgc",
  "x-axis-navigable": "cgq",
  "x-axis-tick-wrapper": "cgZ",
  "x-axis-tick": "cgR",
  "chart-loading-renderer": "cgQ"
};

export default {
  "container": "cqz",
  "form-core": "cqa",
  "label": "cqH",
  "request-description": "cqO",
  "radio-option": "cqm",
  "radio-button": "cqp",
  "inline-inputs": "cqx",
  "placeholder-label": "cqw",
  "placeholder-input": "cqN",
  "placeholder-checkbox": "cqv"
};

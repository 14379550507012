/* import __COLOCATED_TEMPLATE__ from './confirm-trial.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { BadgeStatus, Disclaimer } from '@repo/design-system-kit';
import { CheckoutHeader } from '@repo/domain-kit/pricing';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

interface ConfirmTrialSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ConfirmTrialComponent extends Component<ConfirmTrialSignature> {
  disclaimerBlock = Disclaimer.Block;

  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];

  @tracked hasProductChangeNotAvailableError = false;

  checkoutHeader = CheckoutHeader;
  badgeStatus = BadgeStatus;

  get selectedPricePlan() {
    // @ts-expect-error
    return this.args.context.subscription.product;
  }

  get currentPricePlan() {
    return this.subscriptionManager.currentPricePlan;
  }

  get monthlyPrice() {
    return {
      value:
        this.selectedPricePlan.monthlyPrice.value ||
        this.selectedPricePlan.monthlyPrice.perMonthAmount.value,
      currency:
        this.selectedPricePlan.monthlyPrice.currency ||
        this.selectedPricePlan.monthlyPrice.perMonthAmount.currency,
    };
  }

  get monthDuration() {
    return this.freeTrial?.monthDuration;
  }

  get freeTrial() {
    return this.subscriptionManager.currentSubscription?.availableTrials.find(
      // @ts-expect-error
      ({ productId }) => productId === this.selectedPricePlan.get('id')
    );
  }

  get availableTrialEndDate(): string {
    return this.intl.formatDate(this.freeTrial?.endDate, {
      month: 'short',
      day: '2-digit',
    });
  }

  get showDisclaimerForItalianOrganizations() {
    return (
      variation('feature--boolean-pricing-italian-disclaimers') &&
      this.organizationManager.organization.legalCountry === 'IT'
    );
  }

  get italianDisclaimerText() {
    return this.intl.t('subscription.change.bank-of-italy-disclaimer.body', {
      // @ts-expect-error
      faqUrl: htmlSafe(
        `<a href="https://support-it.qonto.com/hc/it/articles/26999640842513-Restrizioni-in-Italia-e-miglioramento-delle-misure-di-antiriciclaggio" target="_blank" rel="noopener noreferrer"
            data-test-confirm-trial-italian-disclaimer-link>${this.intl.t(
              'subscription.change.bank-of-italy-disclaimer.link'
            )}</a>`
      ),
      htmlSafe: true,
    });
  }

  savePlanTask = dropTask(async () => {
    this.segment.track('plans_change_confirmation_clicked', {
      current_plan: this.currentPricePlan.get('code'),
      target_plan: this.selectedPricePlan.get('code'),
      trial_state: TRACKING_TRIAL_STATE.FREE_TRIAL,
      // @ts-expect-error
      recurrence: this.args.context.recurrence,
    });

    try {
      // @ts-expect-error
      await this.args.context.subscription.save();
      await this.subscriptionManager.refresh();
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (error.status === 422) {
        // @ts-expect-error
        this.hasProductChangeNotAvailableError = error.errors.some(
          // @ts-expect-error
          it => it.detail.code === 'product_change_not_available'
        );

        if (variation('feature--boolean-mp-italy-checkout')) {
          if (this.hasProductChangeNotAvailableError) {
            // @ts-expect-error
            this.args.context.isItalyError = true;
            // @ts-expect-error
            this.args.transitionToNext(true);
            return;
          }
        }
        // @ts-expect-error
      } else if (hasMFAError(error?.errors)) {
        throw error;
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        this.sentry.captureException(error);
      }

      return error;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::ConfirmTrial': typeof ConfirmTrialComponent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './details-and-scheduling.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import { DatePicker, TextAreaField } from '@repo/design-system-kit';
import dayjs from 'dayjs';

// @ts-expect-error
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { RECURRENCE, STATUS } from 'qonto/constants/invoice-subscriptions';

import styles from './details-and-scheduling.strict-module.css';

const CUSTOM_OPTION = 'custom';

interface InvoiceSubscriptionsFormDetailsAndSchedulingSignature {
  // The arguments accepted by the component
  Args: {
    isDisabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSubscriptionsFormDetailsAndSchedulingComponent extends Component<InvoiceSubscriptionsFormDetailsAndSchedulingSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  textAreaField = TextAreaField;
  datePicker = DatePicker;
  styles = styles;

  @tracked customFrequencyValue = this.defaultCustomFrequencyValue;
  @tracked customFrequencyRecurrence = this.defaultCustomFrequencyRecurrence;
  @tracked customPaymentTerm = this.defaultCustomPaymentTermValue;

  @tracked selectedFrequencyOption = this.defaultSelectedFrequencyOption;
  @tracked selectedPaymentTermsOption = this.defaultSelectedPaymentTermsOption;

  @tracked isHeaderTextFieldVisible = false;

  get startDate() {
    // @ts-expect-error
    return this.args?.subscription?.startDate ? parseDate(this.args.subscription.startDate) : null;
  }

  get endDate() {
    // @ts-expect-error
    return this.args?.subscription?.endDate ? parseDate(this.args.subscription.endDate) : null;
  }

  get minStartDate() {
    return parseDate(dayjs().format(DATE_PICKER_FIELD_FORMAT));
  }

  get startDateErrorMessage() {
    // @ts-expect-error
    let errors = this.args.subscription.errors.get('startDate');
    if (!errors) return;

    // @ts-expect-error
    if (errors.some(({ message }) => message === 'required')) {
      return this.intl.t('recurring-invoices.new.form.validation.start-date.empty');
    }
  }

  get selectedPaymentTerms() {
    // @ts-expect-error
    return this.args.subscription.paymentTermsDays === 1
      ? // @ts-expect-error
        this.args.subscription.paymentTermsDays
      : CUSTOM_OPTION;
  }

  get recurrenceOptions() {
    return Object.values(RECURRENCE).map(frequency => {
      return {
        value: frequency,
        label: this.#getLabelForFrequencyCustomUnit(frequency),
      };
    });
  }

  get defaultCustomFrequencyValue() {
    // @ts-expect-error
    return this.isCustomFrequency ? String(this.args.subscription.frequency?.value) : undefined;
  }

  get defaultCustomFrequencyRecurrence() {
    return this.recurrenceOptions.find(
      // @ts-expect-error
      ({ value }) => value === (this.args.subscription.frequency?.recurrence ?? RECURRENCE.MONTHLY)
    );
  }

  get defaultSelectedFrequencyOption() {
    return {
      // @ts-expect-error
      value: this.isCustomFrequency ? CUSTOM_OPTION : this.args.subscription.frequency?.recurrence,
      clearable: this.isCustomFrequency,
    };
  }

  get frequencyErrorMessage() {
    // @ts-expect-error
    let valueErrors = this.args.subscription.errors.get('frequency/value') ?? [];
    // @ts-expect-error
    let recurrenceErrors = this.args.subscription.errors.get('frequency/recurrence') ?? [];
    let errors = [...valueErrors, ...recurrenceErrors];
    if (!errors.length) return;

    if (errors.some(({ message }) => message === 'required')) {
      return this.isCustomFrequencySelected
        ? this.intl.t('recurring-invoices.new.form.validation.custom-frequency.empty')
        : this.intl.t('recurring-invoices.new.form.validation.frequency.empty');
    } else if (
      this.isCustomFrequencySelected &&
      errors.some(({ message }) => message === 'invalid')
    ) {
      return this.intl.t('recurring-invoices.new.form.validation.custom-frequency.invalid-number');
    }
  }

  get frequencyOptions() {
    let labels = {
      [RECURRENCE.WEEKLY]: this.intl.t('recurring-invoices.frequency.option.weekly'),
      [RECURRENCE.MONTHLY]: this.intl.t('recurring-invoices.frequency.option.monthly'),
      [RECURRENCE.QUARTERLY]: this.intl.t('recurring-invoices.frequency.option.quarterly'),
      [RECURRENCE.YEARLY]: this.intl.t('recurring-invoices.frequency.option.yearly'),
    };

    let predefinedOptions = Object.values(RECURRENCE).map(frequency => {
      return {
        value: frequency,
        label: labels[frequency],
        clearable: false,
      };
    });

    return [
      ...predefinedOptions,
      {
        value: CUSTOM_OPTION,
        clearable: true,
        label: this.intl.t('recurring-invoices.frequency.option.custom'),
      },
    ];
  }

  get readOnlyFrequencyLabel() {
    let labels = {
      [RECURRENCE.WEEKLY]: this.intl.t('recurring-invoices.frequency.option.weekly'),
      [RECURRENCE.MONTHLY]: this.intl.t('recurring-invoices.frequency.option.monthly'),
      [RECURRENCE.QUARTERLY]: this.intl.t('recurring-invoices.frequency.option.quarterly'),
      [RECURRENCE.YEARLY]: this.intl.t('recurring-invoices.frequency.option.yearly'),
    };
    return this.isCustomFrequency
      ? this.intl.t('recurring-invoices.frequency.option.custom')
      : // @ts-expect-error
        labels[this.args.subscription.frequency.recurrence];
  }

  get defaultSelectedPaymentTermsOption() {
    return this.isCustomPaymentTerm
      ? this.paymentTermsOptions.find(option => option.value === CUSTOM_OPTION)
      : this.paymentTermsOptions.find(
          // @ts-expect-error
          option => option.value === this.args.subscription.paymentTermsDays
        );
  }

  get defaultCustomPaymentTermValue() {
    // @ts-expect-error
    return this.isCustomPaymentTerm ? String(this.args.subscription.paymentTermsDays) : undefined;
  }

  get paymentTermsErrorMessage() {
    // @ts-expect-error
    let errors = this.args.subscription.errors.get('paymentTermsDays');
    if (!errors) return;

    // @ts-expect-error
    if (errors.some(({ message }) => message === 'required')) {
      return this.isCustomPaymentTermSelected
        ? this.intl.t('recurring-invoices.new.form.validation.custom-payment-terms.empty')
        : this.intl.t('recurring-invoices.new.form.validation.payment-terms.empty');
      // @ts-expect-error
    } else if (errors.some(({ message }) => message === 'invalid')) {
      return this.intl.t(
        'recurring-invoices.new.form.validation.custom-payment-terms.invalid-number'
      );
    }
  }

  get paymentTermsOptions() {
    return [
      {
        value: 0,
        label: this.intl.t('recurring-invoices.new.form.payment-terms.on-receipt'),
        clearable: false,
      },
      {
        value: 15,
        label: this.intl.t('recurring-invoices.new.form.payment-terms.in-15-days'),
        clearable: false,
      },
      {
        value: 30,
        label: this.intl.t('recurring-invoices.new.form.payment-terms.in-30-days'),
        clearable: false,
      },
      {
        value: 'custom',
        label: this.intl.t('recurring-invoices.new.form.payment-terms.custom'),
        clearable: true,
      },
    ];
  }

  get isCustomFrequency() {
    return (
      // @ts-expect-error
      this.args.subscription.frequency?.value !== undefined && // @ts-expect-error
      this.args.subscription.frequency?.value !== 1
    );
  }

  get isCustomFrequencySelected() {
    return this.selectedFrequencyOption?.value === CUSTOM_OPTION;
  }

  get isCustomPaymentTerm() {
    let isCustomTerm = !this.paymentTermsOptions.some(
      // @ts-expect-error
      ({ value }) => value === this.args.subscription.paymentTermsDays
    );
    // @ts-expect-error
    return Boolean(this.args.subscription.paymentTermsDays !== undefined && isCustomTerm);
  }

  get isCustomPaymentTermSelected() {
    return this.selectedPaymentTermsOption?.value === CUSTOM_OPTION;
  }

  get customPaymentTermOption() {
    return {
      value: 'custom',
      label: this.intl.t('recurring-invoices.new.form.payment-terms-custom.days', {
        // @ts-expect-error
        count: this.args.subscription.paymentTermsDays,
      }),
    };
  }

  get isGermanOrganization() {
    return this.organizationManager.organization.legalCountry === 'DE';
  }

  get isHeaderVisible() {
    // @ts-expect-error
    return this.args.subscription.header || this.isHeaderTextFieldVisible;
  }

  get isReadOnly() {
    // @ts-expect-error
    return [STATUS.ACTIVE, STATUS.SUSPENDED].includes(this.args.subscription.status);
  }

  @action
  // @ts-expect-error
  updateFrequency(option) {
    if (!option.value) {
      this.#resetFrequencyToDefaults();
    } else {
      // @ts-expect-error
      this.args.subscription.frequency = {
        value: option.value === CUSTOM_OPTION ? this.customFrequencyValue : 1,
        recurrence:
          // @ts-expect-error
          option.value === CUSTOM_OPTION ? this.customFrequencyRecurrence.value : option.value,
      };
    }

    this.selectedFrequencyOption = option;
    // @ts-expect-error
    this.args.subscription.errors.remove('frequency/value');
    // @ts-expect-error
    this.args.subscription.errors.remove('frequency/recurrence');
  }

  @action
  // @ts-expect-error
  updateCustomFrequencyValue(value) {
    this.customFrequencyValue = value;
    // @ts-expect-error
    this.args.subscription.frequency = {
      // @ts-expect-error
      ...this.args.subscription.frequency,
      value: parseInt(value, 10),
    };
    // @ts-expect-error
    this.args.subscription.errors.remove('frequency/value');
  }

  @action
  // @ts-expect-error
  updateCustomFrequencyRecurrence(option) {
    this.customFrequencyRecurrence = option;
    // @ts-expect-error
    this.args.subscription.frequency = {
      // @ts-expect-error
      ...this.args.subscription.frequency,
      recurrence: option.value,
    };
    // @ts-expect-error
    this.args.subscription.errors.remove('frequency/recurrence');
  }

  @action
  // @ts-expect-error
  updatePaymentTerms(option) {
    if (!option.value) {
      this.customPaymentTerm = undefined;
    }
    // @ts-expect-error
    this.args.subscription.paymentTermsDays =
      option.value === CUSTOM_OPTION ? this.customPaymentTerm : option.value;
    this.selectedPaymentTermsOption = option;
    // @ts-expect-error
    this.args.subscription.errors.remove('paymentTermsDays');
  }

  @action
  // @ts-expect-error
  updateCustomPaymentTerm(days) {
    // @ts-expect-error
    this.args.subscription.paymentTermsDays = parseInt(days, 10);
    this.customPaymentTerm = days;
  }

  @action
  handleClickShowHeaderTextField() {
    this.isHeaderTextFieldVisible = true;
  }

  @action
  handleCloseHeaderTextField() {
    this.isHeaderTextFieldVisible = false;
    // @ts-expect-error
    this.args.subscription.header = '';
  }

  @action
  // @ts-expect-error
  updateStartDate(newValue) {
    // @ts-expect-error
    this.args.subscription.startDate = newValue ? newValue.toString() : null;
  }

  @action
  // @ts-expect-error
  updateEndDate(newValue) {
    // @ts-expect-error
    this.args.subscription.endDate = newValue ? newValue.toString() : null;
  }

  #resetFrequencyToDefaults() {
    this.customFrequencyValue = undefined;
    this.customFrequencyRecurrence = this.recurrenceOptions.find(
      ({ value }) => value === RECURRENCE.MONTHLY
    );
    // @ts-expect-error
    this.args.subscription.frequency = undefined;
  }

  // @ts-expect-error
  #getLabelForFrequencyCustomUnit(value) {
    switch (value) {
      case RECURRENCE.WEEKLY: {
        return this.intl.t('sdd-collections.recurrence.weeks');
      }
      case RECURRENCE.MONTHLY: {
        return this.intl.t('sdd-collections.recurrence.months');
      }
      case RECURRENCE.QUARTERLY: {
        return this.intl.t('recurring-invoices.recurrence.quarters');
      }
      case RECURRENCE.YEARLY: {
        return this.intl.t('sdd-collections.recurrence.years');
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'InvoiceSubscriptions::Form::DetailsAndScheduling': typeof InvoiceSubscriptionsFormDetailsAndSchedulingComponent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './disclaimer.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Checkbox, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { AccountClosingBanner } from 'qonto/react/components/account-closing/account-closing-banner';
import { SubscriptionCloseStepsClosingDate } from 'qonto/react/components/subscription/close/closing-date';

interface SubscriptionCloseStepsDisclaimerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionCloseStepsDisclaimerComponent extends Component<SubscriptionCloseStepsDisclaimerSignature> {
  checkbox = Checkbox;
  accountClosingBanner = AccountClosingBanner;
  disclaimerBlock = Disclaimer.Block;

  @tracked isConfirmationChecked = false;
  @tracked showCheckboxError = false;
  SubscriptionCloseStepsClosingDate = SubscriptionCloseStepsClosingDate;

  @service declare flow: Services['flow'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  constructor(owner: unknown, args: SubscriptionCloseStepsDisclaimerSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.flow.dataContext.hearFromYou = false;
  }

  get hasError() {
    return !this.isConfirmationChecked;
  }

  get isItalianOrganization() {
    return this.organizationManager.organization.legalCountry === 'IT';
  }

  get provisionalDueDate() {
    return this.intl.formatDate(
      // @ts-expect-error
      dayjs(this.flow.dataContext.provisionalDueDate).toDate(),
      {
        format: 'long',
      }
    );
  }

  get planName() {
    return this.subscriptionManager.currentPricePlan.localName;
  }

  get currentPricePlanRecurrence() {
    return this.subscriptionManager.currentSubscription?.recurrence;
  }

  get isSlice3FeatureFlagActive() {
    switch (variation('feature--improve-retention-slice-3')) {
      case 'banner':
        return true;
      case 'banner-screen':
        // @ts-expect-error
        return this.flow.dataContext.closingReasonCategory?.key !== 'service_quality';
      default:
        return false;
    }
  }

  get shouldDisplayBanner() {
    // eslint-disable-next-line no-restricted-syntax -- We are not using this feature for 'solo-basic' users as it might be an expensive feature to offer
    let isSoloBasic = this.subscriptionManager?.currentPricePlan?.groupCode === 'solo_basic';
    // @ts-expect-error
    let isCompanyClosing = this.args.context.closingReasonCategory?.key === 'company_closure';
    let isDisplayingBanner = this.isSlice3FeatureFlagActive && !isSoloBasic && !isCompanyClosing;

    if (isDisplayingBanner) {
      this.segment.track('account-closing_cs-callback_banner-displayed', {
        pricePlan: this.subscriptionManager.currentPricePlan?.code,
        // @ts-expect-error
        closingReasonCategory: this.args.context.closingReasonCategory.key,
      });
    }

    return isDisplayingBanner;
  }

  @action
  abort() {
    // @ts-expect-error
    this.args.abort();
    this.segment.track('account_closing_consequences_keep_account_clicked');
  }

  @action
  toggleConfirmationChecked() {
    this.isConfirmationChecked = !this.isConfirmationChecked;
    if (this.isConfirmationChecked) {
      this.showCheckboxError = false;
    }
  }

  @action
  onBookACall() {
    this.segment.track('account-closing_cs-callback_banner-clicked', {
      pricePlan: this.subscriptionManager.currentPricePlan?.code,
      // @ts-expect-error
      closingReasonCategory: this.args.context.closingReasonCategory.key,
    });
    // @ts-expect-error
    this.flow.dataContext.hearFromYou = true;
    this.flow.next();
  }

  createDeactivationTask = dropTask(async () => {
    try {
      let {
        closingReasonCategory,
        closingReasonSubCategory,
        closingReasonDetails,
        closingReasonDetailsOther,
        // @ts-expect-error
      } = this.args.context;
      if (!this.isConfirmationChecked) {
        this.showCheckboxError = true;
        return;
      }

      let { organization } = this.organizationManager;
      let { due_date } = await organization.createDeactivation({
        closing_reason_category: closingReasonCategory?.key,
        closing_reason_sub_category: closingReasonSubCategory?.key || '',
        closing_reason_details: closingReasonDetails?.key || '',
        closing_reason_details_other: closingReasonDetailsOther || '',
      });

      // @ts-expect-error
      this.args.context.deactivationDueDate = dayjs(due_date).toDate();
      this.segment.track('account_closing_requirements_clicked');
      // @ts-expect-error
      this.args.transitionToNext();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
      let message = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Subscription::Close::Disclaimer': typeof SubscriptionCloseStepsDisclaimerComponent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './digital-card.hbs'; */
import Component from '@glimmer/component';

import { Option } from 'qonto/react/cards/components/flows/choose-card/option';

interface DigitalCardSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class DigitalCard extends Component<DigitalCardSignature> {
  option = Option;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Cards::ChooseCard::DigitalCard': typeof DigitalCard;
  }
}

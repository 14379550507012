/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';
import { SubscriptionSuccess } from '@repo/domain-kit/pricing';

import { TRACKING_TRIAL_STATE } from 'qonto/constants/subscriptions';

interface SubscriptionChangeSuccessSignature {
  // The arguments accepted by the component
  Args: {
    transitionToNext: () => void;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class SubscriptionChangeSuccessComponent extends Component<SubscriptionChangeSuccessSignature> {
  lottiePlayer = LottiePlayer;
  subscriptionSuccess = SubscriptionSuccess;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare subscriptionManager: Services['subscriptionManager'];
  @service declare organizationManager: Services['organizationManager'];

  constructor(owner: unknown, args: SubscriptionChangeSuccessSignature['Args']) {
    super(owner, args);
    this.sendTrackingEventOnLoad();
  }

  sendTrackingEventOnLoad() {
    // @ts-expect-error
    let context = this.args.context;
    let changeType = context.changeType;
    let currentPlan = context.currentPlan;

    if (changeType === 'change-recurrence') {
      this.segment.track('account-closing_change-recurrence_completed', {
        current_plan: currentPlan,
      });
    } else if (changeType === 'switch-plan') {
      this.segment.track('account-closing_switch-plan_completed', {
        current_plan: currentPlan,
      });
    } else if (changeType === 'annual-switch-plan') {
      this.segment.track('account-closing_annual-switch-plan_completed', {
        current_plan: currentPlan,
      });
    }

    this.segment.track('plans_change_confirmation_success', {
      recurrence: context.recurrence,
      trial_state: this.trackingTrialState,
      target_plan: context.subscription.product?.get('code'),
      current_plan: context.currentPricePlanCode,
    });
  }

  get hasInitialTrial() {
    return this.subscriptionManager.currentSubscription?.hasInitialTrial;
  }

  get trackingTrialState() {
    let trialState = TRACKING_TRIAL_STATE.NONE;

    // @ts-expect-error
    if (this.args.context.isFreeTrial) {
      // @ts-expect-error
      trialState = TRACKING_TRIAL_STATE.FREE_TRIAL;
    }

    if (this.hasInitialTrial) {
      // @ts-expect-error
      trialState = TRACKING_TRIAL_STATE.INITIAL_TRIAL;
    }

    return trialState;
  }

  get CTAText() {
    if (this.isConnectUpsell) {
      return this.intl.t('subscription.change.success.connect-cta');
    }
    return this.intl.t('btn.close');
  }

  get subtitle() {
    if (this.isConnectUpsell) {
      return this.intl.t('subscription.change.success.connect-subtitle');
    }
    return this.intl.t('subscription.change.success.subtitle');
  }

  get isConnectUpsell() {
    // @ts-expect-error
    return this.args.context?.refererPage?.includes('connect');
  }

  get typeformLink() {
    const membershipId = this.organizationManager.organization?.id;
    // @ts-expect-error
    const currentPlan = this.args.context?.newPlan;
    // @ts-expect-error
    const previousPlan = this.args.context?.previousPlan;
    // @ts-expect-error
    const recurrence = this.args.context?.recurrence;
    return `${this.intl.t('subscription.change.success.feedback-linkUrl')}#membership_id=${membershipId}&current_plan=${currentPlan}&previous_plan=${previousPlan}&recurrence=${recurrence}`;
  }

  @action
  handleClick() {
    this.segment.track('plans_change_confirmation_success_closed_clicked');
    this.args.transitionToNext();
  }

  @action
  handleClickShareFeedback() {
    this.segment.track('plans_change_confirmation_success_feedback_clicked', {
      // @ts-expect-error
      current_plan: this.args.context.newPlan,
      // @ts-expect-error
      previous_plan: this.args.context.previousPlan,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::SubscriptionChange::Success': typeof SubscriptionChangeSuccessComponent;
  }
}

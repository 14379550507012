export default {
  "container": "cVQ",
  "indicator-container": "cVe",
  "transactions-container": "cVB",
  "indicator": "cVE",
  "cashflow-container": "cVd",
  "cashflow": "cVI",
  "donut-container": "cVt",
  "donut": "cVM"
};

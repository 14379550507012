export default {
  "wrapper": "cVn",
  "header": "cVi",
  "fadeIn": "cVA",
  "header-content": "cVY",
  "with-cta": "cVh",
  "header-title": "cVf title-4",
  "advanced-filters-trigger": "cVK",
  "filters-button": "cVG",
  "filters-button-upsell": "cVr",
  "filters-upsell-item": "cVb",
  "filters-badge": "cVy",
  "chart-container": "cVU",
  "chart-legend-container": "cVj mb-24",
  "chart-watermark-container": "cVW",
  "chart-watermark": "cVu",
  "placeholder-title": "cLS",
  "placeholder-subtitle": "cLc",
  "advanced-filters": "cLq"
};

export default {
  "wrapper": "hd",
  "wrapper-new": "hI",
  "icon-list": "ht",
  "list-item": "hM",
  "total": "hP",
  "box-wrapper": "hl",
  "panel": "hX",
  "panel-top": "hC",
  "list": "hk",
  "container": "hJ",
  "column": "hs",
  "icon": "hF",
  "column-right": "hg"
};

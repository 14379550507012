export default {
  "container": "UW",
  "content": "Uu",
  "buttons": "jS",
  "genders": "jc",
  "birth-details": "jq",
  "has-error": "jZ",
  "gender-option": "jR",
  "radio-button": "jQ"
};

export default {
  "step-container": "yO",
  "content": "ym",
  "detail": "yp",
  "secondary": "yx",
  "reference": "yw",
  "fees-label": "yN",
  "fees-tooltip": "yv",
  "separator": "yo",
  "mt-32": "yn"
};

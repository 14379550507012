export default {
  "container": "ft",
  "back-button": "fM",
  "card-list": "fP",
  "upsell-badge": "fl",
  "pricing": "fX",
  "pricing-period": "fC",
  "ad-modal-button": "fk",
  "eligibility-disclaimer": "fJ"
};

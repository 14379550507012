export default {
  "header": "cPj",
  "center": "cPW",
  "header-placeholder": "cPu",
  "placeholder": "clS",
  "name-block": "clc",
  "block": "clq",
  "detail-block": "clZ",
  "border-top": "clR"
};

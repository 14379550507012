export default {
  "step-container": "cSX",
  "flex-table": "cSC",
  "flex-row": "cSk",
  "flex-cell": "cSJ",
  "with-border": "cSs",
  "with-border-top": "cSF",
  "secondary": "cSg",
  "flex-info": "cSD",
  "amount": "cSV",
  "legal-name": "cSL"
};

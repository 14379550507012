export default {
  "container": "GT",
  "disabled-option": "Gz",
  "content": "Ga",
  "spinner": "GH",
  "subtitle": "GO",
  "details-table": "Gm",
  "caption": "Gp",
  "tooltip-icon": "Gx",
  "tooltip-icon-right": "Gw",
  "card-monthly-cost": "GN",
  "card-monthly-discounted-cost": "Gv",
  "strikethrough": "Go",
  "purple-text": "Gn",
  "budget-label": "Gi",
  "card-cost-label": "GA",
  "card-billing-account": "GY"
};

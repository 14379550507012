export default {
  "row": "czB",
  "cell": "czE body-1",
  "row-cancelled": "czd",
  "cell-amount": "czI",
  "empty": "czt",
  "align-right": "czM",
  "debitor-container": "czP",
  "debitor-info": "czl",
  "debitor-name": "czX body-1",
  "with-sidebar": "czC",
  "method-col": "czk",
  "resource-number": "czJ caption",
  "avatar": "czs"
};

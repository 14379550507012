/* import __COLOCATED_TEMPLATE__ from './transfer-funds.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import dayjs from 'dayjs';

import { TransferFundsScreen } from 'qonto/react/components/flows/subscription/transfer-funds/index.ts';

export default class SubscriptionCloseTransferFundsComponent extends Component {
  @service declare segment: Services['segment'];
  @service declare flow: Services['flow'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  transferFundsScreen = TransferFundsScreen;

  constructor(owner: unknown, args: object) {
    super(owner, args);
    this.segment.track('account-closing_transfer-funds_displayed');
  }

  get provisionalDueDate() {
    return this.intl.formatDate(
      // @ts-expect-error
      dayjs(this.flow.dataContext.provisionalDueDate).toDate(),
      {
        format: 'long',
      }
    );
  }

  @action onClose() {
    this.segment.track('account-closing_transfer-funds_continue-closure');
    this.flow.next();
  }

  @action onTransferFunds() {
    this.segment.track('account-closing_transfer-funds_clicked');
    this.router.transitionTo('transfers.landing');
  }
}

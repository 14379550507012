export default {
  "fieldset": "cPE",
  "numbering-format": "cPd",
  "numbering-fields": "cPI",
  "input-settings": "cPt",
  "label-tooltip": "cPM",
  "tooltip-icon": "cPP",
  "next-invoice-number": "cPl",
  "spacing": "cPX"
};

/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { BadgeStatus } from '@repo/design-system-kit';

// @ts-expect-error
import { NRC_STATUS } from 'qonto/constants/nrc-payments';

interface NrcPaymentsTableItemSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class NrcPaymentsTableItemComponent extends Component<NrcPaymentsTableItemSignature> {
  @service declare intl: Services['intl'];
  badgeStatus = BadgeStatus;

  status = {
    // @ts-expect-error
    [NRC_STATUS.SCHEDULED]: this.intl.t('aeat.status.scheduled'),
    // @ts-expect-error
    [NRC_STATUS.COMPLETED]: this.intl.t('aeat.status.completed'),
    // @ts-expect-error
    [NRC_STATUS.DECLINED]: this.intl.t('aeat.status.declined'),
    // @ts-expect-error
    [NRC_STATUS.CANCELED]: this.intl.t('aeat.status.canceled'),
  };

  icons = {
    [NRC_STATUS.SCHEDULED]: 'status_scheduled',
    [NRC_STATUS.COMPLETED]: 'status_approved',
    [NRC_STATUS.DECLINED]: 'status_cancelled',
    [NRC_STATUS.CANCELED]: 'status_cancelled',
  };

  get statusLevel() {
    // @ts-expect-error
    switch (this.args.item.status) {
      case NRC_STATUS.SCHEDULED:
        return 'waiting';
      case NRC_STATUS.COMPLETED:
        return 'validated';
      case NRC_STATUS.DECLINED:
        return 'error';
      case NRC_STATUS.CANCELED:
        return 'error';
      default:
        return 'waiting';
    }
  }

  get statusIcon() {
    // @ts-expect-error
    return this.icons[this.args.item.status];
  }

  get statusText() {
    // @ts-expect-error
    return this.status[this.args.item.status];
  }

  get isTerminatedPayment() {
    // @ts-expect-error
    return this.args.item.status === 'declined' || this.args.item.status === 'canceled';
  }

  get modeloDescription() {
    // @ts-expect-error
    let taxCode = this.args.item.get('taxCode');
    // @ts-expect-error
    let taxDescription = this.args.item.get('taxDescription');
    return `${this.intl.t('aeat.sidepanel.labels.modelo')} ${taxCode} - ${taxDescription}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Nrc::Table::Item': typeof NrcPaymentsTableItemComponent;
  }
}

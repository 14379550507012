export default {
  "wrapper": "cZk",
  "confirm": "cZJ",
  "plan-info": "cZs",
  "plan-info-left": "cZF",
  "info-title": "cZg body-1",
  "initial-trial-content": "cZD",
  "extra-fees-item": "cZV body-2",
  "extra-fees-container": "cZL",
  "bottom-wrapper": "cZT",
  "total-price-container": "cZz",
  "extra-fees-element": "cZa body-1",
  "border": "cZH",
  "subtitle": "cZO body-2",
  "group-item": "cZm",
  "amount": "cZp",
  "subscription-billing-summary": "cZx",
  "summary": "cZw",
  "summary-list": "cZN",
  "disclaimer": "cZv",
  "disclaimer-revamp": "cZo",
  "error": "cZn",
  "error-link": "cZi",
  "warning-wrapper": "cZA",
  "warning-label": "cZY body-1"
};

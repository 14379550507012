export default {
  "two-column-layout": "cqo",
  "points-list": "cqn",
  "point-title": "cqi",
  "point-content": "cqA",
  "old-container": "cqY",
  "columns": "cqh",
  "left-column": "cqf",
  "right-column": "cqK",
  "video-wrapper": "cqG",
  "selling-points-wrapper": "cqr",
  "actions": "cqb"
};

export default {
  "custom-period-wrapper": "cDZ",
  "fadeIn": "cDR",
  "custom-period-description": "cDQ body-2",
  "header": "cDe",
  "arrow-left": "cDB",
  "body": "cDE",
  "period-wrapper": "cDd",
  "back-button": "cDI",
  "footer": "cDt"
};

export default {
  "card": "cFi",
  "header": "cFA",
  "avatar": "cFY",
  "title": "cFh",
  "subtitle": "cFf",
  "label": "cFK",
  "item": "cFG body-2",
  "header-icon": "cFr",
  "mt-8": "cFb"
};

export default {
  "container": "clr",
  "mapped": "clb",
  "header": "cly",
  "title": "clU caption",
  "select-option-label": "clj",
  "content": "clW",
  "item": "clu body-2",
  "empty": "cXS"
};

export default {
  "container": "cTs",
  "label": "cTF",
  "disabled": "cTg",
  "small": "cTD caption",
  "large": "cTV body-2",
  "caption": "cTL",
  "amount": "cTT",
  "right-aligned": "cTz",
  "expanded": "cTa",
  "amount--current": "cTH",
  "amount--total": "cTO",
  "amount--sep": "cTm"
};

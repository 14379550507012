export default {
  "details-box": "csD",
  "picto": "csV",
  "picto-status": "csL",
  "ghost": "csT",
  "primary-info": "csz",
  "date": "csa",
  "account-infobox": "csH",
  "declined-disclaimer": "csO",
  "nrc-download": "csm"
};

export default {
  "voucher-wrapper": "cQd",
  "left-side": "cQI",
  "left-side__wrapper": "cQt",
  "voucher-illustration": "cQM",
  "left-side__wrapper-title": "cQP",
  "voucher-list": "cQl",
  "left-side__wrapper-details": "cQX",
  "right-side": "cQC",
  "multi-accounts": "cQk",
  "right-side__wrapper": "cQJ"
};

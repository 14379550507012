export default {
  "card": "cFL",
  "header": "cFT",
  "avatar": "cFz",
  "title": "cFa",
  "subtitle": "cFH",
  "label": "cFO",
  "item": "cFm body-2",
  "header-icon": "cFp",
  "dropdown-menu": "cFx",
  "menu-item": "cFw body-2",
  "delete": "cFN"
};

export default {
  "wrapper": "cMR",
  "mobile-mode": "cMQ",
  "logo": "cMe",
  "address-form": "cMB",
  "button-container": "cME",
  "address-form-header": "cMd",
  "address-form-content": "cMI",
  "address-form-address-search": "cMt",
  "ember-basic-dropdown": "cMM",
  "address-form-footer": "cMP",
  "submit-cta": "cMl",
  "form": "cMX",
  "city-section": "cMC",
  "title": "cMk",
  "mobile-title": "cMJ"
};

/* import __COLOCATED_TEMPLATE__ from './clients.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task, timeout } from 'ember-concurrency';

// @ts-expect-error
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { getDefaultLocale } from 'qonto/models/client-hub';
import { ClientCard } from 'qonto/react/components/direct-debit-collections/client-card';

interface FlowsDirectDebitCollectionsSubscriptionClientsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsDirectDebitCollectionsSubscriptionClientsComponent extends Component<FlowsDirectDebitCollectionsSubscriptionClientsSignature> {
  clientCard = ClientCard;

  @service declare store: Services['store'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];

  fetchClientsTask = task(this, { restartable: true }, async (searchQuery = '') => {
    await timeout(DEBOUNCE_MS);

    let clients = await this.store.query('client-hub', {
      ...(searchQuery ? { filter: { name: searchQuery } } : {}),
    });

    if (!searchQuery && clients.length === 0) {
      // @ts-expect-error
      this.args.context.newClient = this.store.createRecord('client-hub', {
        locale: getDefaultLocale(this.organizationManager.organization.legalCountry),
      });
      // @ts-expect-error
      return this.args.pushForwardFlow('direct-debit-collection-client', 'add-client');
    }

    return clients;
  });

  @action
  onAddClient() {
    // @ts-expect-error
    this.args.context.clientToEdit = null;
    // @ts-expect-error
    this.args.context.newClient = this.store.createRecord('client-hub', {
      locale: getDefaultLocale(this.organizationManager.organization.legalCountry),
    });

    // @ts-expect-error
    this.args.pushForwardFlow('direct-debit-collection-client', 'add-client');

    this.segment.track('incoming-direct-debit-flow_new-client_clicked');
  }

  @action
  // @ts-expect-error
  onSelectClient(client) {
    // @ts-expect-error
    this.args.context.clientToEdit = null;
    // @ts-expect-error
    this.args.context.directDebitSubscription.client = client;

    if (!client.email) {
      // @ts-expect-error
      this.args.context.clientToEdit = client;
      // @ts-expect-error
      return this.args.pushForwardFlow('direct-debit-collection-client', 'edit-client');
    } else {
      // @ts-expect-error
      this.args.transitionToNext();

      this.segment.track('incoming-direct-debit-flow_client_selected', {
        client_address: Boolean(client.billingAddress?.streetAddress),
        client_selection: 'existing',
      });
    }
  }

  @action
  // @ts-expect-error
  onEditClient(client) {
    // @ts-expect-error
    this.args.context.clientToEdit = client;

    // @ts-expect-error
    this.args.pushForwardFlow('direct-debit-collection-client', 'edit-client');

    this.segment.track('incoming-direct-debit-flow_edit-client_clicked');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::DirectDebitCollections::Subscription::Clients': typeof FlowsDirectDebitCollectionsSubscriptionClientsComponent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { SubscriptionSuccess } from '@repo/domain-kit/pricing';

interface SubscriptionChangeSuccessSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SubscriptionChangeSuccessComponent extends Component<SubscriptionChangeSuccessSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  subscriptionSuccess = SubscriptionSuccess;

  constructor(owner: unknown, args: SubscriptionChangeSuccessSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.segment.track('checkout_confirmation_success', this.args.context.tracking);
  }

  get typeformLink() {
    const membershipId = this.organizationManager.organization?.id;
    // @ts-expect-error
    let addonCode = this.args.context.targetAddon?.code;

    return `${this.intl.t('subscription.change.addons.success.feedback-linkUrl')}#membership_id=${membershipId}&addon_code=${addonCode}`;
  }

  get shareFeedbackText() {
    // @ts-expect-error
    if (this.args.context.isRemoval) {
      return this.intl.t('subscription.change.addons.success.feedback-cta');
    }

    return null;
  }

  @action
  handleClick() {
    this.segment.track('checkout_confirmation_success_closed_clicked');

    // @ts-expect-error
    this.args.transitionToNext();
  }

  @action
  handleClickShareFeedback() {
    this.segment.track('checkout_confirmation_success_feedback_clicked', {
      // @ts-expect-error
      addon_code: this.args.context.targetAddon.code,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::AddonChange::Success': typeof SubscriptionChangeSuccessComponent;
  }
}

/* import __COLOCATED_TEMPLATE__ from './add-beneficiary.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';

import CURRENCIES from 'qonto/constants/currencies';

interface FlowsTransfersSepaBeneficiaryAddBeneficiarySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersSepaBeneficiaryAddBeneficiaryComponent extends Component<FlowsTransfersSepaBeneficiaryAddBeneficiarySignature> {
  disclaimerBlock = Disclaimer.Block;

  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];
  @service declare featuresManager: Services['featuresManager'];

  @tracked beneficiary;

  constructor(owner: unknown, args: FlowsTransfersSepaBeneficiaryAddBeneficiarySignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let { invoice } = this.args.context;

    this.beneficiary = this.store.createRecord('beneficiary', {
      ...(invoice?.supplierName && { name: invoice.supplierName }),
      ...(invoice?.iban && { iban: invoice.iban }),
      activityTag: 'other_expense',
      currency: CURRENCIES.default,
      organization: this.organizationManager.organization,
      vatRate: null,
    });
  }

  get shouldShowInvalidInvoiceDisclaimer() {
    return (
      // @ts-expect-error
      this.args.context.invoice?.isGermanEInvoice && // @ts-expect-error
      this.args.context.invoice?.isAttachmentNonFinancial
    );
  }

  get shouldShowPhishingDisclaimer() {
    return this.featuresManager.isEnabled('phishingAlertBeneficiaryDisclaimer');
  }

  @action
  // @ts-expect-error
  transitionToNext(newBeneficiary) {
    // @ts-expect-error
    let { context, transitionToNext } = this.args;
    context.beneficiary = newBeneficiary;
    transitionToNext();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::Sepa::Beneficiary::AddBeneficiary': typeof FlowsTransfersSepaBeneficiaryAddBeneficiaryComponent;
  }
}

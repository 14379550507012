export default {
  "color-option": "cBF",
  "color-dot": "cBg",
  "any": "cBD",
  "red": "cBV",
  "orange": "cBL",
  "yellow": "cBT",
  "green": "cBz",
  "blue": "cBa",
  "purple": "cBH",
  "pink": "cBO",
  "black": "cBm",
  "grey": "cBp",
  "brown": "cBx",
  "color-name": "cBw"
};

export default {
  "details": "ccI",
  "disclaimer": "cct",
  "data": "ccM",
  "confirm": "ccP",
  "form": "ccl",
  "textarea": "ccX",
  "preview": "ccC",
  "form-title": "cck title1",
  "form-subtitle": "ccJ title2",
  "divider": "ccs",
  "submit": "ccF",
  "doc": "ccg",
  "amount-input-error": "ccD"
};

export default {
  "header-cell": "clV",
  "empty": "clL",
  "header-content": "clT caption-bold",
  "active": "clz",
  "align-right": "cla",
  "first-col": "clH",
  "mid-col": "clO",
  "status-col": "clm"
};

export default {
  "wrapper": "cLs",
  "header": "cLF",
  "fadeIn": "cLg",
  "header-title": "cLD title-4",
  "transactions-container": "cLV",
  "placeholder-title": "cLL",
  "transaction-item-list-container": "cLT",
  "transaction-item-list": "cLz",
  "item": "cLa"
};

export default {
  "wrapper": "cBu",
  "close-button": "cES",
  "content-wrapper": "cEc",
  "main": "cEq",
  "main-content": "cEZ",
  "description": "cER",
  "mt-26": "cEQ",
  "mt-62": "cEe",
  "user-prompt": "cEB",
  "user-prompt-inappropriate-warning": "cEE",
  "logo-preview": "cEd",
  "static-loader": "cEI",
  "loading-state-illustration": "cEt",
  "sticky-panel": "cEM"
};

export default {
  "item-status": "cLZ",
  "item-date": "cLR",
  "item-amount": "cLQ body-1",
  "item-amount-credit": "cLe",
  "row": "cLB",
  "icon": "cLE",
  "disabled": "cLd",
  "icon-container": "cLI",
  "text-container": "cLt",
  "text-row": "cLM",
  "item-details": "cLP",
  "counterparty-name": "cLl",
  "column": "cLX",
  "item-amount-disabled": "cLC",
  "attachment-tooltip": "cLk",
  "bank-account-avatar": "cLJ"
};

export default {
  "banner": "cFM",
  "inner": "cFP",
  "header": "cFl title-3",
  "content": "cFX",
  "subtitle": "cFC body-1",
  "list": "cFk",
  "item": "cFJ body-1",
  "icon": "cFs",
  "cta": "cFF btn btn--primary",
  "spinner": "cFg",
  "illustration": "cFD",
  "image": "cFV"
};

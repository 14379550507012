export default {
  "company-logo": "cJp",
  "list-item": "cJx",
  "link": "cJw",
  "title-wrapper": "cJN",
  "title": "cJv body-2",
  "subtitle": "cJo caption",
  "truncated-text": "cJn",
  "image-placeholder": "cJi"
};

export default {
  "mandate-list-item": "cCS",
  "selected": "cCc",
  "status-avatar": "cCq",
  "infos": "cCZ",
  "info-title": "cCR body-1",
  "info-title-suspended": "cCQ",
  "info-title-status-suspended": "cCe",
  "info-mandate-count": "cCB body-2"
};

export default {
  "wrapper": "WF",
  "promotion-title": "Wg",
  "comparison-table": "WD",
  "table-head": "WV",
  "title-wrapper": "WL",
  "illustration-wrapper": "WT",
  "illustration": "Wz",
  "role-wrapper": "Wa",
  "role-name": "WH",
  "role-disabled": "WO",
  "role-access": "Wm",
  "disabled-caption": "Wp",
  "table-head-sticky": "Wx",
  "show-border": "Ww",
  "group-row": "WN",
  "table-body": "Wv",
  "group": "Wo",
  "table-body-row": "Wn",
  "feature-description": "Wi body-2",
  "feature": "WA body-2",
  "group-spacer": "WY"
};

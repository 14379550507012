/* import __COLOCATED_TEMPLATE__ from './quote.hbs'; */
import EmberObject, { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Button, Disclaimer, SkeletonLoader } from '@repo/design-system-kit';
import { allSettled, dropTask, restartableTask, timeout } from 'ember-concurrency';
import { TrackedObject } from 'tracked-built-ins';

import { CURRENCIES, NEW_TARGET_CURRENCIES } from 'qonto/constants/international-out/currency';
import {
  DEFAULT_SOURCE_AMOUNT,
  ERROR_CODE,
  QUOTE_CREATION_STATUS,
  RATE_TYPE,
  TYPE,
} from 'qonto/constants/international-out/quote';
import { EVENTS } from 'qonto/constants/international-out/tracking';
// @ts-expect-error
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { createCurrency, extendCurrency } from 'qonto/utils/currency';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { formatExchangeRateWithDetails } from 'qonto/utils/international-out/format';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';
import transformKeys from 'qonto/utils/transform-keys';
// @ts-expect-error
import QuoteValidations from 'qonto/validations/quote';

// @ts-expect-error
function getInitialPayload({ invoice, quote }) {
  if (quote) {
    return {
      sourceAmount: quote.type === TYPE.SOURCE ? quote.sourceAmount : null,
      targetAmount: quote.type === TYPE.TARGET ? quote.targetAmount : null,
    };
  }

  if (invoice) {
    return {
      sourceAmount: null,
      targetAmount: invoice.amount,
      targetCurrency: invoice.currency,
    };
  }
}

// @ts-expect-error
function getInitialState({ bankAccount, invoice, quote, settings }) {
  let { sourceCurrency, targetCurrency } = settings.preferred;

  let initialState = {
    bankAccount,
    sourceCurrency,
    targetCurrency,
  };

  if (quote) {
    return {
      ...initialState,
      sourceAmount: quote?.type === TYPE.SOURCE ? quote.sourceAmount : null,
      targetAmount: quote?.type === TYPE.TARGET ? quote.targetAmount : null,
      targetCurrency: quote?.targetCurrency,
    };
  }

  if (invoice) {
    return {
      ...initialState,
      sourceAmount: null,
      targetAmount: invoice.amount,
      targetCurrency: invoice.currency,
    };
  }

  return initialState;
}

// @ts-expect-error
function getInitialSourceCurrency({ quote, settings }) {
  if (quote) {
    return createCurrency(quote.sourceCurrency);
  }

  return createCurrency(settings.preferred.sourceCurrency);
}

// @ts-expect-error
function getInitialTargetCurrency({ invoice, quote, settings }) {
  if (quote) {
    return createCurrency(quote.targetCurrency);
  }

  if (invoice) {
    return createCurrency(invoice.currency);
  }

  return createCurrency(settings.preferred.targetCurrency);
}

// @ts-expect-error
class QuoteModel extends EmberObject.extend(QuoteValidations) {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];

  @tracked type = null;
  @tracked bankAccount = null;
  @tracked sourceAmount = null;
  @tracked targetAmount = null;
  @tracked sourceCurrency = null;
  @tracked targetCurrency = null;
  @tracked rate = null;
  @tracked fees = null;

  errors = new TrackedObject();

  get shouldValidateTotalAmount() {
    return this.abilities.can('see balance bankAccount');
  }

  get totalAmount() {
    // @ts-expect-error
    return Number(this.sourceAmount || 0) + Number(this.fees?.total || 0);
  }

  clearErrors() {
    Object.keys(this.errors).forEach(attribute => delete this.errors[attribute]);
  }
}

interface Signature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsTransfersInternationalOutNewQuoteComponent extends Component<Signature> {
  button = Button;
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  skeletonLoaderBlock: typeof SkeletonLoader.Block = SkeletonLoader.Block;

  @service declare errors: Services['errors'];
  @service declare internationalOutManager: Services['internationalOutManager'];
  @service declare intl: Services['intl'];
  @service declare localeManager: Services['localeManager'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  // @ts-expect-error
  @tracked sourceCurrencies = [getInitialSourceCurrency(this.args.context)];
  // @ts-expect-error
  @tracked targetCurrencies = [getInitialTargetCurrency(this.args.context)];

  @tracked lastQuoteCreationResult = null;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);

    // @ts-expect-error
    let { origin } = this.args.context.settings;

    this.segment.track(EVENTS.START_FLOW, {
      ...(origin && { origin }),
    });

    // @ts-expect-error
    this.quoteModel = QuoteModel.create(
      // @ts-expect-error
      getOwner(this).ownerInjection(),
      // @ts-expect-error
      getInitialState(this.args.context)
    );

    allSettled([
      this.getTargetCurrenciesTask.perform(),
      // @ts-expect-error
      this.createAndUpdateQuoteTask.perform(getInitialPayload(this.args.context)),
    ])
      .then(([targetCurrenciesSettled]) => {
        if (targetCurrenciesSettled.state === 'fulfilled' && targetCurrenciesSettled.value) {
          this.targetCurrencies = targetCurrenciesSettled.value;
        }
      })
      .catch(ignoreCancelation);
  }

  get actionTask() {
    if (this.hasFailed) {
      return this.onRetryTask;
    }

    return this.onTransitionTask;
  }

  get formattedExchangeRate() {
    // @ts-expect-error
    return formatExchangeRateWithDetails(this.quoteModel.rate?.value || 0);
  }

  get hasFailed() {
    // @ts-expect-error
    return this.lastQuoteCreationResult?.status === QUOTE_CREATION_STATUS.UNEXPECTED_ERROR;
  }

  get extendedAndSortedSourceCurrencies() {
    // @ts-expect-error
    return this.#extendCurrencies(this.sourceCurrencies)?.sort((firstCurrency, secondCurrency) =>
      firstCurrency.code.localeCompare(secondCurrency.code)
    );
  }

  get extendedAndSortedTargetCurrencies() {
    return this.#extendCurrencies(this.targetCurrencies, NEW_TARGET_CURRENCIES)?.sort(
      // @ts-expect-error
      (firstCurrency, secondCurrency) => firstCurrency.code.localeCompare(secondCurrency.code)
    );
  }

  get feesTooltipMessage() {
    // @ts-expect-error
    if (this.quoteModel.targetCurrency === CURRENCIES.EUR) {
      return this.intl.t('international-out.quote.tooltips.eur-transfer-fee');
    }

    // @ts-expect-error
    let { fees } = this.args.context;

    return this.intl.t('international-out.quote.tooltips.transfer-fee', {
      pricingFee: fees?.variable,
      minimumFee: fees?.minimum,
    });
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get isButtonDisplayed() {
    // @ts-expect-error
    return this.quoteModel.errors?.global?.code !== ERROR_CODE.NO_PAYMENT_OPTION_AVAILABLE;
  }

  get targetCurrencyNotice() {
    // @ts-expect-error
    let { fees, targetCurrency } = this.quoteModel;
    // @ts-expect-error
    let { targetCurrency: preferredTargetCurrency } = this.args.context.settings.preferred;

    if (targetCurrency === preferredTargetCurrency && fees === null) {
      return null;
    }

    switch (targetCurrency) {
      case CURRENCIES.EUR:
        return this.intl.t('international-out.quote.information.eur');
      case CURRENCIES.MYR:
        return this.intl.t('international-out.quote.information.myr');
      case CURRENCIES.USD:
        return this.intl.t('international-out.quote.information.usd');
      default:
        return null;
    }
  }

  @action
  // @ts-expect-error
  updateBankAccount(account) {
    // @ts-expect-error
    this.args.context.bankAccount = account;
    // @ts-expect-error
    this.quoteModel.bankAccount = account;
  }

  createQuoteTask = dropTask(
    async ({
      targetAmount = null,
      sourceAmount = DEFAULT_SOURCE_AMOUNT,
      // @ts-expect-error
      targetCurrency = this.quoteModel.targetCurrency,
      // @ts-expect-error
      sourceCurrency = this.quoteModel.sourceCurrency,
    } = {}) => {
      let status = QUOTE_CREATION_STATUS.SUCCESS;
      let payload = {
        ...(sourceAmount && { sourceAmount: Number(sourceAmount) }),
        ...(targetAmount && { targetAmount: Number(targetAmount) }),
        sourceCurrency,
        targetCurrency,
      };

      try {
        return await this.internationalOutManager.createQuote(payload);
      } catch (error) {
        // @ts-expect-error
        if (error.errors?.[0] && Object.values(ERROR_CODE).includes(error.errors[0].code)) {
          status = QUOTE_CREATION_STATUS.VALIDATION_ERROR;

          // @ts-expect-error
          let { code, meta, source } = transformKeys(error.errors[0]);

          let {
            ABOVE_MAXIMUM_AMOUNT,
            BELOW_MINIMUM_AMOUNT,
            NO_PAYMENT_OPTION_AVAILABLE,
            TARGET_AMOUNT_VOLATILE,
          } = ERROR_CODE;

          let errorCodesMapping = {
            [ABOVE_MAXIMUM_AMOUNT]: {
              attribute: source?.pointer?.slice(1),
              message: this.intl.t('international-out.quote.errors.amount.too-high', {
                formattedAmount: meta?.formattedAmount,
              }),
            },
            [BELOW_MINIMUM_AMOUNT]: {
              attribute: source?.pointer?.slice(1),
              message: this.intl.t('international-out.quote.errors.amount.too-low', {
                formattedAmount: meta?.formattedAmount,
              }),
            },
            [NO_PAYMENT_OPTION_AVAILABLE]: {
              message: this.intl.t('international-out.quote.errors.currency.unavailable', {
                // @ts-expect-error
                targetCurrency: this.quoteModel.targetCurrency,
              }),
            },
            [TARGET_AMOUNT_VOLATILE]: {
              message: this.intl.t('international-out.quote.errors.exchange-rate.floating', {
                // @ts-expect-error
                sourceCurrency: this.quoteModel.sourceCurrency,
              }),
            },
          };

          if (code in errorCodesMapping) {
            // @ts-expect-error
            let { attribute, message } = errorCodesMapping[code];
            // @ts-expect-error
            this.quoteModel.errors[attribute ?? 'global'] = {
              code,
              message,
            };
          }

          return;
        }

        status = QUOTE_CREATION_STATUS.UNEXPECTED_ERROR;

        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(
          // @ts-expect-error
          this.errors.messageForStatus(error) || this.intl.t('toasts.errors.generic')
        );
      } finally {
        // @ts-expect-error
        this.lastQuoteCreationResult = {
          status,
          payload,
        };
      }
    }
  );

  createAndUpdateQuoteTask = dropTask(async payload => {
    // @ts-expect-error
    this.quoteModel.clearErrors();

    let response = await this.createQuoteTask.perform(payload);

    if (!response) return;

    let { quote, fees } = response;

    // @ts-expect-error
    this.args.context.fees = fees;

    // @ts-expect-error
    if (!isEmpty(this.quoteModel.sourceAmount) || !isEmpty(this.quoteModel.targetAmount)) {
      this.#setQuote(quote, fees);
    } else {
      this.#setRate(quote.rate);
    }
  });

  getTargetCurrenciesTask = dropTask(async () => {
    try {
      return await this.internationalOutManager.getTargetCurrencies();
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  onAmountUpdateTask = dropTask(async (amount, type) => {
    let amountAttribute = type === TYPE.SOURCE ? 'sourceAmount' : 'targetAmount';

    await timeout(DEBOUNCE_MS);

    // @ts-expect-error
    this.quoteModel[amountAttribute] = amount;

    // @ts-expect-error
    let { [amountAttribute]: amountValidations } = this.quoteModel.validations.attrs;
    if (isEmpty(amount) || amountValidations.isInvalid) {
      return;
    }

    this.segment.track(EVENTS.INPUT_AMOUNT, { amount_input_type: type });

    await this.createAndUpdateQuoteTask.perform({
      sourceAmount: type === TYPE.SOURCE ? amount : null,
      targetAmount: type === TYPE.TARGET ? amount : null,
    });
  });

  onRetryTask = dropTask(async () => {
    if (!this.lastQuoteCreationResult) return;

    let { payload } = this.lastQuoteCreationResult;

    await this.createAndUpdateQuoteTask.perform({
      // @ts-expect-error
      ...payload,
      // @ts-expect-error
      sourceAmount: payload.sourceAmount || null,
      // @ts-expect-error
      targetAmount: payload.targetAmount || null,
    });
  });

  onSourceAmountUpdateTask = restartableTask(async amount => {
    await this.onAmountUpdateTask.perform(amount, TYPE.SOURCE);
  });

  onTargetAmountUpdateTask = restartableTask(async amount => {
    await this.onAmountUpdateTask.perform(amount, TYPE.TARGET);
  });

  onTargetCurrencyUpdateTask = dropTask(async currencyCode => {
    // @ts-expect-error
    let { quoteModel } = this;
    let { sourceAmount, targetAmount, targetCurrency, type } = quoteModel;

    if (targetCurrency === currencyCode) return;

    quoteModel.targetCurrency = currencyCode;

    this.segment.track(EVENTS.SELECT_CURRENCY, { currency_type: TYPE.TARGET });

    let { sourceAmount: sourceAmountValidations, targetAmount: targetAmountValidations } =
      quoteModel.validations.attrs;

    if (sourceAmountValidations.isInvalid || targetAmountValidations.isInvalid) return;

    let payload;

    if (type) {
      payload = {
        sourceAmount: type === TYPE.SOURCE ? sourceAmount : null,
        targetAmount: type === TYPE.TARGET ? targetAmount : null,
      };
    }

    await this.createAndUpdateQuoteTask.perform(payload);
  });

  onTransitionTask = dropTask(async () => {
    // @ts-expect-error
    await this.quoteModel.validate();

    // @ts-expect-error
    let { sourceAmount, targetAmount, totalAmount } = this.quoteModel.validations.attrs;

    let hasValidationErrors =
      // @ts-expect-error
      isEmpty(this.quoteModel.sourceAmount) ||
      // @ts-expect-error
      isEmpty(this.quoteModel.targetAmount) ||
      sourceAmount.isInvalid ||
      targetAmount.isInvalid ||
      totalAmount.isInvalid;

    // @ts-expect-error
    let hasServerErrors = Boolean(Object.keys(this.quoteModel.errors).length);

    // @ts-expect-error
    let isQuoteCreated = Boolean(this.args.context.quote);

    if (hasValidationErrors || hasServerErrors || !isQuoteCreated) {
      this.#scrollToError();
      return;
    }

    // @ts-expect-error
    this.args.transitionToNext();
  });

  // @ts-expect-error
  #extendCurrencies = (currencies, newCurrenciesList = []) =>
    // @ts-expect-error
    currencies.map(currency =>
      extendCurrency({
        currency,
        // @ts-expect-error
        options: { locale: this.localeManager.locale, newCurrenciesList },
      })
    );

  #scrollToError() {
    next(() => scrollIntoView('[data-has-error]'));
  }

  // @ts-expect-error
  #setQuote = (quote, fees) => {
    // @ts-expect-error
    this.args.context.quote = quote;

    let { sourceAmount, targetAmount, rate, rateType, type } = quote;

    // @ts-expect-error
    this.quoteModel.setProperties({
      type,
      sourceAmount,
      targetAmount,
      rate: {
        value: rate,
        type: rateType,
      },
      fees: {
        total: fees.total,
      },
    });
  };

  // @ts-expect-error
  #setRate = rate => {
    // @ts-expect-error
    this.args.context.quote = null;

    // @ts-expect-error
    this.quoteModel.setProperties({
      rate: {
        value: rate,
        type: RATE_TYPE.FIXED,
      },
    });
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Transfers::InternationalOut::New::Quote': typeof FlowsTransfersInternationalOutNewQuoteComponent;
  }
}

export default {
  "member-details": "cCd",
  "header": "cCI",
  "no-email": "cCt",
  "header-img": "cCM",
  "invited-persona": "cCP",
  "revoked-persona": "cCl",
  "invited-icon": "cCX",
  "revoked-icon": "cCC",
  "revised": "cCk",
  "invitable-icon": "cCJ",
  "dropdown": "cCs",
  "header-body": "cCF",
  "status": "cCg",
  "name": "cCD title-3",
  "email": "cCV caption-bold",
  "actions": "cCL body-2",
  "actions-cards": "cCT",
  "actions-transactions": "cCz",
  "body": "cCa",
  "expense-permissions-section": "cCH",
  "body-title": "cCO title-4",
  "body-attr": "cCm",
  "body-label": "cCp",
  "attr-buttons": "cCx",
  "body-role": "cCw small",
  "details-actions": "cCN",
  "actions-item": "cCv",
  "disclaimer": "cCo",
  "permissions": "cCn",
  "permission": "cCi",
  "permission-label": "cCA",
  "permission-check": "cCY",
  "permission-missing": "cCh",
  "editable-section": "cCf",
  "hris-placeholder": "cCK",
  "imported-from": "cCG",
  "integration-logo": "cCr",
  "permission-value": "cCb",
  "tooltip": "cCy",
  "check-icon": "cCU"
};

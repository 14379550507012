export default {
  "wrapper": "cBZ input-field mb-4",
  "error": "cBR",
  "inactive": "cBQ",
  "hidden-value": "cBe",
  "percentage-sign": "cBB",
  "black": "cBE",
  "input-field": "cBd",
  "disabled": "cBI"
};

export default {
  "upsell-cta": "ux",
  "discover-badge": "uw",
  "role-selection-item": "uN",
  "unavailable": "uv",
  "role-headline": "uo",
  "illustration": "un",
  "role-description": "ui",
  "description-item": "uA",
  "description-icon": "uY",
  "description-text": "uh",
  "current-role": "uf"
};

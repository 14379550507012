export default {
  "checks": "rh",
  "content": "rf",
  "form": "rK",
  "title": "rG",
  "description": "rr",
  "wrapper": "rb",
  "aside": "ry",
  "amount-field": "rU"
};

export default {
  "integration-item": "cdW",
  "icon-container": "cdu",
  "content": "cIS",
  "title-container": "cIc",
  "new-badge": "cIq",
  "title": "cIZ",
  "description": "cIR",
  "no-attributes": "cIQ",
  "connect-cta": "cIe"
};
